(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ChatService', ChatService);

    ChatService.$inject = ['$q'];

    function ChatService($q, WatiFactory, $localStorage) {

        var MICROSERVICE_SOCKET_URI = document.location.protocol + '//admin.' + document.location.hostname.split('.').slice(1).join('.') + '/chat';

        var BROKER_WHATSAPP = '/broker/new-message-wati/';
        var BROKER_MESSENGER = '/broker/new-message-messenger/';
        var BROKER_INSTAGRAM = '/broker/new-message-instagram/';
        var BROKER_SYSTEM_NOTIFICATION = '/broker/new-system-notification/';
        var BROKER_SYSTEM_UPDATE_NOTIFICATION = '/broker/update-system-notification/';

        var socket = {
            client: null,
            stomp: null
        };

        var deferred = $q.defer();

        var service = {
            subscribeOnWhatsapp: subscribeOnWhatsapp,
            subscribeOnMessenger: subscribeOnMessenger,
            subscribeOnInstagram: subscribeOnInstagram,
            subscribeOnNewNotifications: subscribeOnNewNotifications,
            subscribeOnUpdateNotifications: subscribeOnUpdateNotifications,
            connectWS: connectWS
        };

        return service;

        function connectWS(callback) {
            
            if (socket.stomp && socket.stomp.connected) {
            
                callback();
                return;
            }
        
            socket.client = SockJS(MICROSERVICE_SOCKET_URI);
            socket.stomp = Stomp.over(socket.client);
         
            socket.stomp.connect({}, function () {
                deferred.resolve(socket.stomp.connected);
                callback();
            }, function () {
                reconnect(callback);
            });
        }

        function reconnect(callback) {
            console.info('Tentando reconectar...');
            setTimeout(function() {
                connectWS(callback);
            }, 5000);
        }

        function subscribeOnMessenger(origin, onMessage) {
            socket.stomp.subscribe(BROKER_MESSENGER + origin, function(message) {
                onMessage(JSON.parse(message.body));
            });
        }

        function subscribeOnWhatsapp(origin, onMessage) {
            socket.stomp.subscribe(BROKER_WHATSAPP + origin, function(message) {
                onMessage(JSON.parse(message.body));
            });
        }

        function subscribeOnNewNotifications(origin, onMessage) {
            socket.stomp.subscribe(BROKER_SYSTEM_NOTIFICATION + origin, function(message) {
                onMessage(JSON.parse(message.body));
            });
        }

        function subscribeOnUpdateNotifications(origin, onMessage) {
            socket.stomp.subscribe(BROKER_SYSTEM_UPDATE_NOTIFICATION + origin, function(message) {
                onMessage(JSON.parse(message.body));
            });
        }

        function subscribeOnInstagram(origin, onMessage) {
            socket.stomp.subscribe(BROKER_INSTAGRAM + origin, function(message) {
                onMessage(JSON.parse(message.body));
            });
        }
    }

})();
