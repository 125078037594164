(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('TopNavbarController', TopNavbarController);

    TopNavbarController.$inject = ['$localStorage', '$state', 'Auth', 'StoreService', '$rootScope', 'Principal', '$scope', '$translate',
        '$uibModal', 'Constants', 'NotificationService', 'WsService', 'SettingsService', 'ChatService', 'ErpsSettingsService'];

    function TopNavbarController($localStorage, $state, Auth, StoreService, $rootScope, Principal, $scope, $translate, 
        $uibModal, Constants, NotificationService, WsService, SettingsService, ChatService, ErpsSettingsService) {

        var storeChangeSuccess = $scope.$on('storeChangeSuccess', storeChangeSuccessHandler);
        var NOTIFICATION_AUDIO_FILE = "../../assets/audio/notification_sound.mp3"

        var vm = this;

        vm.logout = logout;
        vm.changeSession = changeSession;
        vm.changeState = changeState;
        vm.hub = $localStorage.hub;

        vm.myStoresLoading = false;
        vm.notificationsCount = 0;
        vm.messagesCount = 0;
        vm.configuration = null;
        vm.whatsappSupportMessage = null;

        $rootScope.$on("UpdateNotificationCount", function(){
            $scope.parentmethod();
        });

        $scope.parentmethod = function() {
            getNotificationsInfo();
        }

        $rootScope.$on("settings-updated", function() {
            SettingsService.getRegister($localStorage.userId, $localStorage.defaultStore.storeId).then(function (data) {
                vm.systemNotificationsSound = data['soundNotification'];
            });
        });

        init();

        function init() {
            loadAccount();
            loadErpConfiguration();
        }

        function loadAccount() {
            Principal.identity().then(function(account) {
                if (account){
                    vm.account = angular.copy(account);
                    subscribeButtonHandler();
                    updateDistance();
                    if (vm.account.userProfiles){
                        vm.userProfile = vm.account.userProfiles[0];
                        vm.isAdminAccount = vm.account.userProfiles[0].storeId == $rootScope.ADMIN_SYSTEM_ID;
                    }
                    if (!vm.isAdminAccount) {
                        getMyStores();
                        getStoreInfo();
                    }
                    getNotificationsInfo();
                }
            });
        }

        function loadErpConfiguration() {
            if ($localStorage.defaultStore) {
                ErpsSettingsService.getERPConfigurationByStoreId($localStorage.defaultStore.storeId)
                    .then(function (response) {
                        if (response.data && response.data.id) {
                            vm.configuration = response.data;
                            updateDistance();
                        }
                    });
            }
        }

        vm.getWhatsappIntegrationMessage = function() {
            var storeName = vm.store.name;
            var erpName = vm.getErpName();
            var message = $translate.instant('global.top-navbar.message.warning.whatsapp-support-message',
                { storeName: storeName, erpName: erpName });
            return 'https://api.whatsapp.com/send?phone=+558398931539&text='.concat(message);
        }

        vm.getTrialWarningMessage = function() {
            return $translate.instant('global.top-navbar.message.warning.trial',
                { remainingTime: vm.account.remainingTime });
        }
        
        vm.getIntegrationTokenFailedWarningMessage = function() {
            var erpName = vm.getErpName();
            return $translate.instant('global.top-navbar.message.warning.integration-token-failed',
                { erpName: erpName });
        }

        vm.getIntegrationUnexpectedWarningMessage = function() {
            var erpName = vm.getErpName();
            return $translate.instant('global.top-navbar.message.warning.integration-unexpected-failed',
                { erpName: erpName });
        }

        function playAudio() {
            var audio = new Audio();
            audio.src = NOTIFICATION_AUDIO_FILE;
            audio.load();
            audio.play();
        }

        function subscribeOnNotification(message) {
            getNotificationsInfo();
            playAudio();
        }

        function updateNotifications(message) {
            getNotificationsInfo();
        }

		vm.giftCardCheckout = function (){
			/*PlanModalFactory.choosePlan().then(function() {
            });*/
 			return $uibModal.open({
                templateUrl: 'app/account/checkout/gift-card/gift-card-checkout.html',
                controller: 'GiftCardCheckoutController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard : false
            }).result;
		}

        function getNotificationsInfo() {
            NotificationService.getNotificationInfo(
                $localStorage.defaultStore.storeId,
                vm.account.id
            ).then(function (data) {
                vm.notificationsCount = data.notificationsCount;
                vm.messagesCount = data.messagesCount;
            });
        }

        function getStoreInfo() {
            if ($localStorage.defaultStore){
                StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                    vm.store = response.data;
                    $localStorage.defaultStore.category = vm.store.categoryKey;
                    if(!vm.account.acceptedTerm) {
                        vm.account.acceptedTerm = true;
                        vm.checkedTermOfUse(vm.store.storeType);
                        subscribeButtonHandler();
                    }
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                });

                WsService.getInfo().then(function (result) {
                    vm.wsToken = result.token;

                    ChatService.connectWS(function () {
                        ChatService.subscribeOnNewNotifications($localStorage.defaultStore.storeId, subscribeOnNotification);
                        ChatService.subscribeOnNewNotifications(vm.account.id, subscribeOnNotification);
                        ChatService.subscribeOnUpdateNotifications($localStorage.defaultStore.storeId, updateNotifications);
                    });
                });
            }

        }

        function updateDistance()  {
            var cct = document.getElementById('cct');
            var breadcrumb = document.getElementById('breadcrumb');
            var navMenu = document.getElementById('nav-menu');
            if (vm.isTrial(vm.account) || vm.isIntegrationFailed(vm.configuration)) {
                if(cct) {
                    cct.style.marginTop = "85px"
                }
                if(breadcrumb) {
                    breadcrumb.style.marginTop = "35px"
                }
                if(navMenu) {
                    navMenu.style.marginTop = "35px"
                }
            }
        }

        function logout() {
            var failedAudioMessages = $localStorage.failedMessages
            Auth.logout();
            $state.go('main-login');
            $localStorage.failedMessages = failedAudioMessages
        }

        function getMyStores() {
            vm.myStoresLoading = true;
            StoreService.getMyStores().then(function(response) {
                vm.myStores = response.data;
                vm.myStoresLoading = false;
            });
        }

        function changeSession(storeId) {
            Auth.changeSession(storeId).then(
                function() {
                    $state.transitionTo('home', {}, {
                        reload: true,
                        inherit: false,
                        notify: true
                    });

                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                });
        }

        function storeChangeSuccessHandler() {
            getMyStores();
            getStoreInfo();
        }

        function changeState(state) {
            $state.go(state);
        }

        function subscribeButtonHandler() {
            // Prevent the user from clicking the subscribe button multiple times.
            Notification.requestPermission().then(function (result) {
                if (result === 'denied') {
                    return;
                }
                navigator.serviceWorker.getRegistration().then( function (registration) {
                    if (registration) {
                        registration.pushManager.getSubscription().then(function (subscribed) {
                            registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: urlB64ToUint8Array('BF3vlHug3aBrL9pvwdHtLiOXi-AbBoZ_e-W5H_VMFoM8NwFV7vuRXz4jb2FJ6tcYSNJJqQqkfFgjkUn8jsAA7FA')
                            }).then(function (subscription) {
                                const subscriptionJSON = JSON.parse(JSON.stringify(subscription));
                                SettingsService.registerToReceiveNotifications(subscriptionJSON.endpoint,
                                    subscriptionJSON.keys.auth, subscriptionJSON.keys.p256dh, $localStorage.userId, $localStorage.defaultStore.storeId).then(function (data) {
                                    vm.registerId = data.id;
                                    vm.webNotifications = true;
                                });
                            });
                        });
                    } else {
                        vm.webNotifications = false;
                    }
                });
            });

        }

        function urlB64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (var i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }

        $rootScope.$on('user-logged-out', function () {
            $state.go($state.current, {}, {reload: true});
        });

        SettingsService.getRegister($localStorage.userId, $localStorage.defaultStore.storeId).then(function (data) {
            vm.systemNotificationsSound = data['soundNotification'];
        });

        vm.checkedTermOfUse = function (storeType) {
            $uibModal.open({
                templateUrl: 'app/admin/term-of-use/term-of-use.html',
                controller: 'TermOfUseController',
                controllerAs: 'vm',
                scope: $scope,
                keyboard: false,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    storeType: function() {
                        return storeType;
                    }
                }
            });
        }

        vm.openNotificationsModal = function () {
            vm.notificationsModal = $uibModal.open({
                templateUrl: 'app/admin/notification/modal/notificationList.html',
                controller: 'NotificationListController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false
            }).closed.then(function (response) {
                getNotificationsInfo();
            });
        }

        vm.openChecklistModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/checklist/checklist.html',
                controller: 'ChecklistController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false
            }).result.then(function (response) {
                if (response) {
                    alert(response);
                }
            });
        }

        vm.isTrial = function(account) {
            return !isNullOrUndefined(account) && !isNullOrUndefined(account.remainingTime)
                && account.remainingTime > 0 && account.remainingTime <= 7;
        }

        vm.isIntegrationFailed = function() {
            return !isNullOrUndefined(vm.configuration) && !isNullOrUndefined(vm.configuration.id)
                && (vm.configuration.status == 'FAILED' || vm.configuration.status == 'INVALID_TOKEN');
        }

        vm.getErpName = function() {
            if (!isNullOrUndefined(vm.configuration) && !isNullOrUndefined(vm.configuration.partnerId)) {
                switch (vm.configuration.partnerId) {
                    case Constants.ErpPartnerId.VAREJO_ONLINE:
                        return "Varejo Online";
                    case Constants.ErpPartnerId.NEXAAS:
                        return "Nexaas";
                    case Constants.ErpPartnerId.BLING:
                        return "Bling";
                    default:
                        return "";
                }
            } else {
                return "";
            }
        }
    }
})();
