(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('CustomersDetailController', CustomersDetailController);

    /** @ngInject */

    function CustomersDetailController($rootScope, $scope, CustomerModalFactory,
                                       toastr, $translate, moment, CustomerService,
                                       NoteTagService, $localStorage) {
        var format = "L";
        var vm = this;
        vm.notes = [];
        vm.tags = [];
        vm.newNoteText = "";
        vm.newTagText = "";
        vm.isWritingNote = false;
        vm.isEditingNote = false;
        vm.currentlyEditingNote = 0;
        vm.customer = {};

        vm.showModalEditCustomer = showModalEditCustomer;
        vm.showModalDeleteCustomer = showModalDeleteCustomer;

        $scope.$on('selectedCustomer', function (event, customer) {
            if (customer) {
                vm.customer = customer;
                loadNotesTags(vm.customer.id);
            }
        });

        function addNine(numberWithNine) {
            if (numberWithNine.includes('+55') && numberWithNine.length === 13) {
                numberWithNine = numberWithNine.slice(0, 5) + '9' + numberWithNine.slice(5, numberWithNine.length);
            }
            return numberWithNine;
        }

        function showModalEditCustomer() {
            vm.customer.phone = addNine(vm.customer.phone);
            CustomerModalFactory.editCustomer(vm.customer).then(function (result) {
                vm.customer = result.data;
                vm.customer.birthdate = moment.utc(vm.customer.birthdate);
                $rootScope.$broadcast('refreshListCustomer');
                toastr.success($translate.instant(result.headers["x-adminapp-alert"]));
            });
        }

        function showModalDeleteCustomer() {
            CustomerModalFactory.deleteCustomer(vm.customer).then(function (result) {
                if (result) {
                    CustomerService.delete(vm.customer.id).then(function (result) {
                        toastr.success($translate.instant(result.headers["x-adminapp-alert"]));
                        $rootScope.$broadcast('deletedCustomer');
                    });
                }
            });
        }

        vm.sendMessageToWhastapp = function (number) {
            var whatsappApiDomain;
            if (detectar_mobile()) {
                whatsappApiDomain = "https://api.whatsapp.com/send?phone=";
            } else {
                whatsappApiDomain = "https://web.whatsapp.com/send?phone=";
            }
            return whatsappApiDomain + number;
        }

        //2021-10-01T15:51:08.760+0000
        function convertToDatePT(date) {
            var newDate = date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
            return newDate;
        }

        function loadNotesTags(customerId) {
            NoteTagService.getNotesTags(customerId).then(function (notesTags) {
                vm.notes = notesTags.filter(function (noteTag) {
                    noteTag.timestamp = convertToDatePT(noteTag.timestamp.substring(0, 10)) + " " + noteTag.timestamp.substring(11, 16);
                    if (noteTag.id && noteTag.type == 'note') {
                        return true
                    };
                })

                vm.tags = notesTags.filter(function (noteTag) {
                    if (noteTag.id && noteTag.type == 'tag') {
                        return true
                    };
                });

                vm.loadingCustomerInfos = false;
            })
        }

        vm.deleteNoteTag = function (tagId, isTag) {
            NoteTagService.deleteNoteTag(tagId).then(function () {
                isTag ?
                    vm.tags = vm.tags.filter(function (value) {
                        return value.id != tagId && value.type == "tag";
                    }) :
                    vm.notes = vm.notes.filter(function (value) {
                        return value.id != tagId && value.type == "note";
                    })
            })
        }

        vm.startWritingNote = function (isEditingNote, noteId, index) {
            if (isEditingNote) {
                vm.newNoteText = vm.notes[index].text;
            }

            vm.isWritingNote = true;
            vm.isEditingNote = isEditingNote;
            vm.currentlyEditingNote = noteId;
        }

        /**
         * adds a new tag or note to the user
         * @param {the text of the tag that's being created} newNoteTagText
         * @param {is true if it's suppose to add a tag} isTag
         */
        vm.addNoteTag = function (newNoteTagText, isTag) {
            if (newNoteTagText == "" || newNoteTagText == null) {
                toastr.warning('O texto não pode ser vazio');
                return;
            }

            if (vm.isEditingNote == true) {
                NoteTagService.updateNote({ noteId: vm.currentlyEditingNote, text: newNoteTagText }).then(function (result) {
                    for (var i = 0; i < vm.notes.length; i++) {
                        if (vm.notes[i].id == vm.currentlyEditingNote) {
                            vm.notes[i].text = result.text;
                            break;
                        }
                    }
                })
            } else {
                NoteTagService.createNoteTag({
                    "customerId": vm.customer.id,
                    "text": newNoteTagText,
                    "type": isTag ? "tag" : "note",
                    "createdBy": $localStorage.userId
                }).then(function (result) {
                    result.timestamp = convertToDatePT(result.timestamp.substring(0, 10)) + " " + result.timestamp.substring(11, 16);
                    isTag ? vm.tags.push(result) : vm.notes.push(result);
                });
            }
            isTag ? vm.newTagText = "" : vm.newNoteText = "";

            vm.isWritingNote = false;
            vm.isEditingNote = false;
        }

        vm.stopWritingNote = function () {
            vm.newNoteText = "";
            vm.isWritingNote = false;
            vm.isEditingNote = false;
        }
    }

})();
