(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('reports', {
                parent: 'admin',
                url: '/reports',
                abstract: true,
                data: {
                    authorities: ['STORE_MANAGE_REPORT'],
                    pageTitle: "Report"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/reports/reports-dashboard.html',
                        controller: 'ReportsDashboardController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
            .state('consumers', {
                parent: 'reports',
                url: '/consumers',
                data: {
                    pageTitle: "reports.public-consumer.page-title",
                    // specialClass: "md-skin fixed-nav mini-navbar"
                },
                views: {
                    'coupon-view': {
                        templateUrl: 'app/admin/reports/tabs/coupon.html',
                        controller: 'ConsumerCouponReportController as vm'
                    },
                    'contact-view': {
                        templateUrl: 'app/admin/reports/tabs/contact.html',
                        controller: 'ConsumerContactReportController as vm'
                    },
                    'sale-view': {
                        templateUrl: 'app/admin/reports/tabs/sale.html',
                        controller: 'ConsumerSaleReportController as vm'
                    }
                }
            })
            .state('engagements', {
                parent: 'admin',
                url: '/reports/engaged-users',
                data: {
                    authorities: ['STORE_MANAGE_REPORT'],
                    pageTitle: "reports.engagement.page-title",
                    // specialClass: "md-skin fixed-nav mini-navbar"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/reports/engagement.html',
                        controller: 'EngagementController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
            .state('follow-up', {
                parent: 'admin',
                url: '/reports/follow-up',
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/reports/follow-up-dashboard.html'
                    }
                },
                data: {
                    pageTitle: "reports.follow-up.title",
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
            .state('engagements-user', {
                parent: 'engagements',
                url: '/user/:id/engagements?{from:string}',
                data: {
                    authorities: ['STORE_MANAGE_REPORT'],
                    pageTitle: "reports.engagement.user.page-title",
                    // specialClass: "md-skin fixed-nav mini-navbar"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/reports/user/user.engagements.html',
                        controller: 'UserEngagementsController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
            .state('store-performance', {
                parent: 'admin',
                url: '/reports/store-performance',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.store-performance"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('chat-performance', {
                parent: 'admin',
                url: '/reports/chat-performance?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.chat-performance"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('seller-performance', {
                parent: 'admin',
                url: '/reports/seller-performance?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.seller-performance"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('seller-individual-performance', {
                parent: 'admin',
                url: '/reports/seller-individual-performance?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.seller-individual-performance"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('abandoned-cart-dashboard', {
                parent: 'admin',
                url: '/reports/abandoned-cart-dashboard?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.abandoned-cart-dashboard"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('virtual-bag-performance', {
                parent: 'admin',
                url: '/reports/virtual-bag-performance?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.virtual-bag-performance"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('crm-dashboard', {
                parent: 'admin',
                url: '/reports/crm-dashboard?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.crm-dashboard"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('hub-performance', {
                parent: 'admin',
                url: '/reports/hub-performance?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.hub-performance"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('hub-malwee-painel-multibrand', {
                parent: 'admin',
                url: '/reports/hub-painel-multibrand?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.hub-malwee-painel-multibrand"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('hub-subscriptions', {
                parent: 'admin',
                url: '/reports/hub-subscriptions?{report:string}',
                views: {
                    'content@app': {
                        controller: 'PerformanceController as vm',
                        templateUrl: 'app/admin/reports/performance/performance.html'
                    }
                },
                data: {
                    pageTitle: "global.menu.admin.hub-subscriptions"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });;

    }
})();
