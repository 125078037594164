(function () {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("ModalFactory", ModalFactory);

    /** @ngInject */
    function ModalFactory($uibModal) {
        var factory = {
            openModal: openModal,
            openSimpleModal: openSimpleModal,
            openWarningModal: openWarningModal,
            openSelectGMBAccountAndCategoryModal: openSelectGMBAccountAndCategoryModal,
            openWhatsappQRCodeModal: openWhatsappQRCodeModal,
            openWhatsappConfirmModal: openWhatsappConfirmModal,
            openWhatsappResumeQrCodeModal: openWhatsappResumeQrCodeModal,
            openDetailInvoice: openDetailInvoice,
            openDiscountModal: openDiscountModal,
            openSyncWithHubModal: openSyncWithHubModal
        };
        return factory;

        function openModal(modalTitle, modalMessage, modalSubMessage, buttonMessage, buttonBodyMessage,
            buttonOk, buttonAction, withHeader, withFooter, centralIcon, interactiveTranslate,
            functionTranslate, interactiveFunction, imageStyle, imageCentralIcon, fontSizeMessage, closeAfterAction) {
            $uibModal.open({
                templateUrl: 'app/services/global/message/message.modal.html',
                controller: 'MessageModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {

                    modalTitle: function () {
                        return modalTitle;
                    },
                    modalMessage: function () {
                        return modalMessage;
                    },
                    modalSubMessage: function () {
                        return modalSubMessage;
                    },
                    buttonMessage: function () {
                        return buttonMessage;
                    },
                    buttonBodyMessage: function () {
                        return buttonBodyMessage;
                    },
                    buttonOk: function () {
                        return buttonOk;
                    },
                    buttonAction: function () {
                        return buttonAction;
                    },
                    withHeader: function () {
                        return withHeader;
                    },
                    withFooter: function () {
                        return withFooter;
                    },
                    centralIcon: function () {
                        return centralIcon;
                    },
                    functionTranslate: function () {
                        return functionTranslate;
                    },
                    interactiveTranslate: function () {
                        return interactiveTranslate;
                    },
                    interactiveFunction: function () {
                        return interactiveFunction;
                    },
                    imageStyle: function () {
                        return imageStyle;
                    },
                    imageCentralIcon: function () {
                        return imageCentralIcon;
                    },
                    fontSizeMessage: function () {
                        return fontSizeMessage;
                    },
                    closeAfterAction: function () {
                        return closeAfterAction;
                    }

                }
            }).result;
        }

        function openSimpleModal(modalTitle, modalMessage, buttonConfirmText, buttonCancelText, buttonConfirmAction) {
            return $uibModal.open({
                templateUrl: 'app/services/global/message/message.modal.simple.html',
                controller: 'MessageModalSimpleController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {

                    modalTitle: function () {
                        return modalTitle;
                    },
                    modalMessage: function () {
                        return modalMessage;
                    },
                    buttonConfirmText: function () {
                        return buttonConfirmText;
                    },
                    buttonCancelText: function () {
                        return buttonCancelText;
                    },
                    buttonConfirmAction: function () {
                        return buttonConfirmAction;
                    }

                }
            }).result;
        }

        function openWarningModal(modalTitle, modalMessage, smallText, warningText, buttonConfirmText, buttonCancelText, buttonConfirmAction, buttonCancelAction) {
            return $uibModal.open({
                templateUrl: 'app/services/global/message/warning-modal.html',
                controller: 'WarningMessageModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {

                    modalTitle: function () {
                        return modalTitle;
                    },
                    modalMessage: function () {
                        return modalMessage;
                    },
                    smallText: function () {
                        return smallText
                    },
                    warningText: function () {
                        return warningText
                    },
                    buttonConfirmText: function () {
                        return buttonConfirmText;
                    },
                    buttonCancelText: function () {
                        return buttonCancelText;
                    },
                    buttonConfirmAction: function () {
                        return buttonConfirmAction;
                    },
                    buttonCancelAction: function () {
                        return buttonCancelAction;
                    }
                }
            }).result;
        }

        function openSelectGMBAccountAndCategoryModal(googleAccounts) {
            return $uibModal.open({
                templateUrl: 'app/integrations/google-my-business/gmb-select-gmb-configurations-modal.html',
                controller: 'SelectGMBConfigurationsController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {

                    googleAccounts: function(){
                        return googleAccounts;
                    }

                }
            }).result;
        }

        function openWhatsappQRCodeModal(biWhatsappConfiguration) {
            return $uibModal.open({
                templateUrl: 'app/integrations/whatsapp/whatsapp-qrcode-viewer.html',
                controller: 'WhatsappQrCodeController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {

                    biWhatsappConfiguration: function() {
                        return biWhatsappConfiguration;
                    }

                }
            }).result;
        }

        function openWhatsappResumeQrCodeModal(resume) {
            return $uibModal.open({
                templateUrl: 'app/integrations/whatsapp/whatsapp-resume-detail.html',
                controller: 'WhatsappResumeQrCodeController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {

                    resume: function() {
                        return resume;
                    }

                }
            }).result;
        }

        function openWhatsappConfirmModal(messagesInfo) {
            return $uibModal.open({
                templateUrl: 'app/integrations/whatsapp/whatsapp-job-modal.html',
                controller: 'WhatsappConfirmationModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {

                    messagesInfo: function() {
                        return messagesInfo;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('integrations');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]

                }
            }).result;
        }

        function openDetailInvoice(data, userProfile) {
            return $uibModal.open({
                templateUrl: 'app/admin/invoices/invoice-modal-detail.html',
                controller: 'InvoiceModalDetailController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    invoice: function() {
                        return data;
                    },
                    userProfile: function() {
                        return userProfile;
                    }
                }
            }).result;
        }

        function openDiscountModal(modalTitle, modalSubtitle, inputLabel, inputFooter, buttonConfirmText, buttonCancelText, buttonConfirmAction) {
            return $uibModal.open({
                templateUrl: 'app/admin/flyers/apply-discount-modal/apply-discount.modal.html',
                controller: 'ApplyDiscountModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {

                    modalTitle: function () {
                        return modalTitle;
                    },
                    modalSubtitle: function () {
                        return modalSubtitle;
                    },
                    inputLabel: function () {
                        return inputLabel;
                    },
                    inputFooter: function () {
                        return inputFooter;
                    },
                    buttonConfirmText: function () {
                        return buttonConfirmText;
                    },
                    buttonCancelText: function () {
                        return buttonCancelText;
                    },
                    buttonConfirmAction: function () {
                        return buttonConfirmAction;
                    }

                }
            }).result;
        }

        function openSyncWithHubModal(modalTitle, modalMessage, smallText, warningText, checkBoxes, buttonConfirmText, buttonCancelText, buttonConfirmAction) {
            return $uibModal.open({
                templateUrl: 'app/admin/flyers/sync-with-hub-modal/sync-with-hub.html',
                controller: 'SyncWithHubModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    modalTitle: function () {
                        return modalTitle;
                    },
                    modalMessage: function () {
                        return modalMessage;
                    },
                    smallText: function () {
                        return smallText
                    },
                    warningText: function () {
                        return warningText
                    },
                    checkBoxes: function () {
                        return checkBoxes
                    },
                    buttonConfirmText: function () {
                        return buttonConfirmText;
                    },
                    buttonCancelText: function () {
                        return buttonCancelText;
                    },
                    buttonConfirmAction: function () {
                        return buttonConfirmAction;
                    }

                }
            }).result;
        }
    }

})();
