(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .controller('CustomerModalConstroller', CustomerModalConstroller);

    /** @ngInject */
    function CustomerModalConstroller(customer, $uibModalInstance, $scope,
                                      UserService, CustomerService, StoreInfoFactory,
                                      toastr, $translate, moment, NoteTagService,
                                      $localStorage) {
        var format = 'L';

        var vm = this;

        vm.maskLocale = '99/99/9999';

        vm.customer = customer || {};
        vm.cancel = cancel;
        vm.getGenderKey = getGenderKey;
        vm.init = init;
        vm.save = save;

        function init() {
            loadGenders();
            dateTimePicker();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function loadGenders() {
            UserService.listUserGenders().then(function (genders) {
                vm.genders = genders;
            });
        }

        function removeNine(numberWithNine) {
            if (numberWithNine.includes('+55') && numberWithNine.length === 14) {
                numberWithNine = numberWithNine.substring(0, 5) + numberWithNine.substring(6, numberWithNine.length);
            }
            return numberWithNine;
        }

        vm.documentInputType = function() {
            if (vm.customer && vm.customer.customerIdentity) {
                var documentType = vm.customer.customerIdentity.length <= 11 ? 'INDIVIDUAL' : 'COMPANY';
                return documentType;
            }
            return 'INDIVIDUAL';
        }

        function save() {
            if (vm.formAddEditCustomer.$valid) {
                vm.isSaving = true;
                var customerToSave = angular.copy(vm.customer);
                customerToSave.phone = removeNine(customerToSave.phone);
                customerToSave.birthDate = customerToSave.birthDate ? moment.utc(customerToSave.birthDate, format).toDate() : undefined;
                customerToSave.storeId = StoreInfoFactory.getStoreId();
                customerToSave.type = vm.documentInputType();
                customerToSave.customerIdentity = customerToSave.customerIdentity === "" ? undefined : customerToSave.customerIdentity;

                if (vm.customer.id) {
                    CustomerService.update(customerToSave).then(onSuccess, onError);
                } else {
                    CustomerService.create(customerToSave).then(onSuccess, onError);
                }

                function onSuccess(result) {
                    vm.isSaving = false;
                    $uibModalInstance.close(result);

                        if (result.data.notes !== '') {
                            NoteTagService.createNoteTag({
                                "customerId": result.data.id,
                                "text": result.data.notes,
                                "type": "note",
                                "createdBy": $localStorage.userId
                            }).then(function (result) {
                            });
                        }
                }

                function onError(error) {
                    vm.isSaving = false;
                    if (error.data && error.data.data) {
                        switch(error.data.data) {
                            case "CUSTOMER_DOCUMENT_DUPLICATED_ERROR":
                                toastr.error($translate.instant("customer.error.duplicateCustomerIdentity"));
                                return;
                            case "CUSTOMER_EMAIL_DUPLICATED_ERROR":
                                toastr.error($translate.instant("customer.error.emailExists"));
                                return;
                            case "CUSTOMER_PHONE_DUPLICATED_ERROR":
                                toastr.error($translate.instant("customer.error.phoneExists"));
                                return;
                        }
                    }
                    if (error.data.headers["x-adminapp-params"]) {
                        toastr.error($translate.instant(error.data.headers["x-adminapp-params"].concat(".").concat(error.data.headers["x-adminapp-error"])));
                    } else {
                        toastr.error($translate.instant("global.messages.somethingWrong"));
                    }
                }
            }
        }

        function getGenderKey(value) {
            return "customer".concat(".").concat(value);
        }

        function dateTimePicker() {
            vm.dateOfBirthOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                showClear: true,
                maxDate: moment()
            };

            angular.element('#dateOfBirth').datetimepicker(vm.dateOfBirthOptions).on('dp.change', function (ev) {
                if (ev.date) {
                    $scope.$apply(function () {
                        vm.customer.birthDate = ev.date.format(format);
                    });
                } else {
                    $scope.$apply(function () {
                        vm.customer.birthDate = undefined;
                    });
                }
            });

            vm.customer.birthDate = vm.customer.birthDate ? moment.utc(vm.customer.birthDate).format(format) : null;
        }
    }

})();
