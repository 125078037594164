(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FlyersFormController', FlyersFormController);

    function FlyersFormController($translate, $timeout, $scope, $window, $localStorage, $q, $state, $uibModal, $stateParams, toastr, moment,
                                  FlyerCategoryService, StoreService, FlyersService, BrandService, ColorService, ShowcasesService,
                                  ErpsSettingsService, $rootScope) {

        const MAX_IMAGE_SIZE = 10240;
        const ICON_ACTIVE = 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/pointactive.png';
        const ICON_INACTIVE = 'content/images/pointinactive.png';
        const DATE_FORMAT = 'L LT';
        const now = moment(moment(), DATE_FORMAT).toDate();
        const SRC_IMAGE_DEFAULT = 'content/images/1-image-default-product.png';
        const SRC_IMAGE_DETAIL_DEFAULT = 'content/images/2-image-default-product_mini.png';

        var vm = this;


        loadStore();
        loadErpConfiguration();

        vm.configuration = null;
		vm.regexForValueZero = '([1-9]{1}[0-9]{0,2})(\.[0-9]{3})*\,[0-9]{2}|[0-9]{0,3}(\.[0-9]{3})*\,([0-9][1-9]|[1-9][0-9])';
        vm.isEdit = false;
        vm.isLoading = true;
        vm.currencyConfigurations = getCurrencyListConfiguration();
        vm.transactionalUserInteractions = ["RENT", "SALE"];
        vm.enableDemoVideo = false;
        vm.srcImgDefault = SRC_IMAGE_DEFAULT;
		vm.srcImgDetailDefault = SRC_IMAGE_DETAIL_DEFAULT;
        vm.imgsFlyers = [
            {
                id: '1',
                src: vm.srcImgDefault,
                isDefault: true,
                isThumbnail: false
            },
            {
                id: '2',
                src: vm.srcImgDefault,
                isDefault: true,
                isThumbnail: false
            },
            {
                id: '3',
                src: vm.srcImgDefault,
                isDefault: true,
                isThumbnail: false
            },
            {
                id: '4',
                src: vm.srcImgDefault,
                isDefault: true,
                isThumbnail: false
            }
        ]

		vm.listBlobs = [];

        $scope.windowWidth = $window.innerWidth;

        $window.onresize = function(event) {
            $timeout(function() {
              $scope.windowWidth = $window.innerWidth;
            });
          };

        if ($stateParams.id) {
            vm.isEdit = true;
            loadFlyer($stateParams.id);
        } else {
            loadData();
        }
        $scope.sortableOptions = {
            update: function (e, ui) {
                //if (vm.getCountDefaultImageFlyer() > 0) {
                    vm.moveImageFlyerToFirstIndex();
                //}
            }
        };

        $scope.$watch(function () {
            return document.getElementById("content").clientWidth;
        }, function (newVal, oldVal) {
            if (!document.getElementById('brand'))
                return;
            if ((newVal - oldVal) > 20 || (oldVal - newVal) > 20) {
                var autoChild = document.getElementById('brand').firstElementChild;
                var el = angular.element(autoChild);
                el.scope().$mdAutocompleteCtrl.hidden = true;
            }
        });

        // Auxiliaries for brand entities
        vm.querySearchBrand     = querySearchBrand;
        vm.selectedBrandChange  = selectedBrandChange;
        vm.newBrand             = newBrand;
        vm.brands               = [];

        // Auxiliaries for category entities
        vm.querySearchCategory  = querySearchCategory;
        vm.selectedCategoryChange = selectedCategoryChange;
        vm.newCategory          = newCategory;

        // Auxiliaries for color entities
        vm.querySearchColor     = querySearchColor;
        vm.selectedColorChange  = selectedColorChange;
        vm.newColor             = newColor;
       	vm.colors               = [];

        vm.imageSize = 600;
        vm.flyerImage = '';
        vm.firstZoom = true;
        vm.isSaving = false;
        vm.selectedSizes = [];

        vm.enableTags = false;
        vm.enableDimension = false;
        vm.enableSchedules = false;
        vm.enableBatchSale = false;
        vm.enableIndiviualPrice = true;
        vm.enableRules = false;
        vm.enableMeasures = false;

        vm.measureList = [];
		vm.capitalize = function (str){
			if (typeof str !== 'string') {
				return '';
			}
			return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
		}

        vm.flyer = {
            discountPercentageValue: undefined,
            currency: 'REAL',
            currencyInstance: vm.currencyConfigurations.REAL,
            discountType: 'DISCOUNT_FIXED',
            details: [],
            tags: [],
            isLaunch: false,
            isBasic: false,
            validityTypeDate: false,
            isPublished: false,
			addImageFileDTO: [],
            type: 'SALE'
        };

        vm.mainMap = {
            center: {
                latitude: 0,
                longitude: 0
            },
            zoom: 10,
            controls: {},
            bounds: {},
            markers: [],
            window: {
                model: {},
                show: false,
                options: {
                    pixelOffset: {
                        width: -1,
                        height: -65
                    }
                },
                closeClick: function () {
                    this.show = false;
                }
            },
            markerEvents: {
                dragend: function (marker) {
                    vm.refreshMapWithCoordinates(marker.position.lat(), marker.position.lng());
                },
                mouseover: function (marker, eventName, model) {
                    vm.mainMap.window.model = model;
                    vm.mainMap.window.show = true;
                },
                mouseout: function () {
                    vm.mainMap.window.show = false;
                },
                click: function (marker) {
                    for (var i = 0; i < vm.mainMap.markers.length; i++) {
                        if (vm.mainMap.markers[i].id === marker.key) {
                            if (vm.mainMap.markers[i].clicked) {
                                vm.mainMap.markers[i].options.icon = ICON_INACTIVE;
                                vm.mainMap.markers[i].clicked = false;
                            } else {
                                vm.mainMap.markers[i].options.icon = ICON_ACTIVE;
                                vm.mainMap.markers[i].clicked = true;
                            }
                        }
                    }
                }
            }
        };

        init();

        function init() {
            loadStoreData();
        }

        function loadStoreData() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                if (response && response.data) {
                    vm.storeCategory = response.data.categoryKey;
                    loadCurrencyPrefix(response.data);
                }
            })
        }

        function loadCurrencyPrefix(store) {
            var key = store.currency ? store.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            vm.currencyPrefix = config.prefix;
            vm.flyer.currency = key;
            vm.flyer.currencyInstance = config;
            console.log(vm.flyer);
        }

        vm.onChangeEan = function (value, index) {
            if (value.ean === "" || value.ean === null || value.ean === undefined) {
                return false;
            }
            for (var i = 0; i < vm.flyer.details.length; i++) {
                var details = vm.flyer.details[i];
                if (index !== i && value.ean === details.ean) {
                    return true;
                }
            }
            return false;
        }

        vm.onChangeSize = function (value, index) {
            if (value.size === "" || value.size === null || value.size === undefined) {
                return false;
            }
            for (var i = 0; i < vm.flyer.details.length; i++) {
                var details = vm.flyer.details[i];
                if (index !== i && value.size === details.size) {
                    return true;
                }
            }
            return false;
        }

        vm.checkIfLaunchSelected = function () {
            return vm.flyer.isLaunch;
        }

        vm.updateLaunchOrRemove = function () {
            vm.flyer.isLaunch = !vm.flyer.isLaunch;
        }

        vm.checkIfBasicSelected = function () {
            return vm.flyer.isBasic;
        }

        vm.updateBasicOrRemove = function () {
            vm.flyer.isBasic = !vm.flyer.isBasic;
        }

        vm.openInput = function (evt) {
            document.getElementById('fileInput').click();
        }

        vm.changeIndividualPrice = function () {
            vm.enableIndiviualPrice = !vm.enableIndiviualPrice;
            if (vm.enableIndiviualPrice) {
                vm.flyer.details = [];
                vm.selectedSizes = [];
                if(vm.enableMeasure) {
                    vm.measureList = [];
                    vm.addMeasures();
                } else {
                    vm.measureList = [];
                }
            } else {
                vm.addDetail();
                vm.flyer.purchaseValue = undefined;
                vm.flyer.discountedValue = undefined;
                vm.flyer.inventory = undefined;
                if(vm.flyer.dimension){
                    vm.flyer.dimension.weight = '';
                    vm.flyer.dimension.height = '';
                    vm.flyer.dimension.width = '';
                    vm.flyer.dimension.length = '';
                }
                if(vm.flyer.measureList){
                    vm.flyer.measureList = [];
                }
            }
        }

        vm.changeTags = function () {
            if (!vm.enableTags) {
                vm.flyer.tags = [];
            }
        }

        vm.changeDimension = function() {
            if (!vm.enableDimension) {
                if(vm.flyer.dimension){
                    vm.flyer.dimension.weight = '';
                    vm.flyer.dimension.height = '';
                    vm.flyer.dimension.width = '';
                    vm.flyer.dimension.length = '';
                }
                vm.flyer.details.forEach(function (detail) {
                    detail.dimension = undefined;
                });
            }
        }

        vm.changeMeasure = function() {
            if (!vm.enableMeasure) {
                if(vm.flyer.dimension){
                    vm.flyer.measureList = [];
                }
                vm.flyer.details.forEach(function (detail) {
                    detail.measureList = [];
                });
                vm.measureList = [];
            } else {
                vm.addMeasures();
            }
        }

        vm.changeBatchSale = function () {
            if (!vm.enableBatchSale) {
                vm.flyer.bulkQuantity = undefined;
                vm.flyer.discountBulkPercentage = undefined;
            }
        }

        vm.changeRulesForUse = function() {
            if (!vm.enableRules) {
                vm.flyer.rulesForUse = undefined;
            }
        }

        vm.changeSchedules = function () {
            if (vm.enableSchedules) {
                vm.startDateOptions = {
                    format: DATE_FORMAT,
                    sideBySide: true,
                    ignoreReadonly: true,
                    locale: moment.locale(),
                    allowInputToggle: true
                };

                vm.expireDateOptions = {
                    format: DATE_FORMAT,
                    sideBySide: true,
                    ignoreReadonly: true,
                    minDate: moment(),
                    locale: moment.locale(),
                    allowInputToggle: true,
                    useCurrent: false
                };

                angular.element('#startDateTime').datetimepicker(vm.startDateOptions).on('dp.change', function (ev) {
                    if (ev.date) {
                        vm.flyer.startDate = ev.date.format(DATE_FORMAT);
                        if (vm.flyer.expirationDate && !checkExpirationDate()) {
                            vm.flyer.expirationDate = undefined;
                            angular.element('#expirationDateTime').data("DateTimePicker").minDate(ev.date || now);
                        } else {
                            angular.element('#expirationDateTime').data("DateTimePicker").minDate(now);
                        }
                        $scope.$apply(function () {
                            vm.flyerForm.startDate.$setValidity('minDate', true);
                        });
                    } else {
                        vm.flyer.startDate = undefined;
                    }
                });

                angular.element("#expirationDateTime").datetimepicker(vm.expireDateOptions).on('dp.change', function (ev) {
                    if (ev.date) {
                        vm.flyer.expirationDate = ev.date.format(DATE_FORMAT);
                        $scope.$apply(function () {
                            vm.flyerForm.expirationDate.$setValidity('required', true);
                        });
                    } else {
                        vm.flyer.expirationDate = undefined;
                    }
                });
            } else {
                vm.flyer.startDate = undefined;
                vm.flyer.expirationDate = undefined;
                vm.flyer.immediateAvailability = true;
                vm.flyer.validityTypeDate = true;
            }
        }

        vm.onImmediateAvailabilityChange = function () {
            if (vm.flyer.immediateAvailability) {
                vm.flyerForm.startDate.$setValidity('minDate', true);
                angular.element('#startDateTime').data("DateTimePicker").clear();
            }
        }

        vm.onValidityTypeDateChange = function (checked) {
            if (checked) {
                angular.element('#expirationDateTime').data("DateTimePicker").clear();
            }
        };

		vm.loadImages = function () {
			vm.imgsFlyers[0].isThumbnail = true;
			for (var i = 0 ; i < vm.imgsFlyers.length; i++){
				var random = new Date().getTime();
				var src = null;
				if (i != 0){
					src = '/flyer/api/' + vm.flyer.id + '__'+ i + '/picture?type=large&ts=' + random;
				}else{
					src = '/flyer/api/' + vm.flyer.id + '/picture?type=large&ts=' + random;
				}
				var img = new Image();
				img.src = src;
				img.id = i
				img.onload = function() {
					vm.imgsFlyers[this.id].src = this.src;
					vm.imgsFlyers[this.id].isDefault = false;
					vm.moveImageFlyerToFirstIndex();
				}
				img.onerror = function() {
					vm.imgsFlyers[this.id].src = SRC_IMAGE_DEFAULT;
				}
			}
			this.$apply;
		}

        vm.typeChanged = function () {
            if (vm.flyer.type === 'RENT') {
                vm.flyer.maxUsageByUser = 1;
                vm.flyer.maxUsages = 1;
                vm.flyer.usageLimitByUser = false;
                vm.flyer.validityTypeNumber = false;
                vm.flyer.validityTypeDate = true;
            }

            vm.flyer.bulkQuantity = null;
            vm.flyer.discountBulkPercentage = null;

            if (vm.transactionalUserInteractions.includes(vm.flyer.type)) {
                vm.flyer.currency = 'REAL';
                vm.flyer.currencyInstance = vm.currencyConfigurations.REAL;
                vm.disabledCurrency = true;
            } else {
                vm.disabledCurrency = false;
            }
        }

        vm.checkAllMarkers = function () {
            var icon;
            if (vm.allSalesPoint) {
                icon = ICON_ACTIVE;
            } else {
                icon = ICON_INACTIVE;
            }
            for (var i = 0; i < vm.mainMap.markers.length; i++) {
                vm.mainMap.markers[i].options.icon = icon;
                vm.mainMap.markers[i].clicked = vm.allSalesPoint;
            }
        }

        vm.uploadImage = function () {
            if(vm.isSaving) {
                return;
            }
            vm.isSaving = true;
            if (vm.flyerForm.$invalid) {
                toastr.warning("Verifique os campos invalidos")
                angular.forEach(vm.flyerForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                vm.isSaving = false;
                return;
            }

            if (vm.flyer.brand === null) {
                toastr.warning($translate.instant('brand.messages.warning.selectBrand'));
                vm.isSaving = false;
                return;
            }

            if (vm.fullCategory === null) {
                toastr.warning($translate.instant('categorys.messages.warning.selectCategory'));
                vm.isSaving = false;
                return;
            }


			var cont = 0;
			var contImageModified = vm.imgsFlyers.length;
			vm.flyer.addImageFileDTO = [];
			for (var i = 0; i < vm.imgsFlyers.length; i++) {
				if (vm.imgsFlyers[i].src.includes(SRC_IMAGE_DEFAULT)){
					vm.flyer.addImageFileDTO.push(createJsonImage(null, null, i, 'DELETE'));
					cont = cont + 1;
					if (cont == contImageModified){
                        if (vm.flyer.addImageFileDTO.length > 0) {
							saveFlyer();
                        } else {
                            toastr.error($translate.instant('flyers.messages.create.error'));
                            vm.isSaving = false;
                        }
                        vm.loadingImage = false;
					}
				}else if (!vm.imgsFlyers[i].src.includes('base64')){
					vm.flyer.addImageFileDTO.push(createJsonImage(vm.imgsFlyers[i].src, null, i, 'UPDATE'));
					cont = cont + 1;
					if (cont == contImageModified){
                        if (vm.flyer.addImageFileDTO.length > 0) {
							saveFlyer();
                        } else {
                            toastr.error($translate.instant('flyers.messages.create.error'));
                            vm.isSaving = false;
                        }
                        vm.loadingImage = false;
					}
				}else{
                	var blob = dataURItoBlob(vm.imgsFlyers[i].src);
	                FlyersService.saveFlyerMultiImage(blob, i).then(function (response) {
	                    $timeout(function () {
	                        vm.flyer.addImageFileDTO.push(response.data);
                            cont = cont + 1;
							if (cont == contImageModified){
		                        if (vm.flyer.addImageFileDTO.length > 0) {
									saveFlyer();
		                        } else {
		                            toastr.error($translate.instant('flyers.messages.create.error'));
                                    vm.isSaving = false;
		                        }
		                        vm.loadingImage = false;
							}
	                    });
	                }, function () {
	                    toastr.error($translate.instant('flyers.messages.create.image.uploadError'));
	                    vm.isSaving = false;
	                    vm.loadingImage = false;
	                });
				}
			}
        }

        function loadStore() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                vm.defaultStoreCategory = response.data.categoryKey;
            });
        }

        function loadErpConfiguration() {
            ErpsSettingsService.getERPConfigurationByStoreId($localStorage.defaultStore.storeId)
                .then(function (response) {
                    if (response.data && response.data.id) {
                        vm.configuration = response.data;
                    }
                });
        }

        function createJsonImage(uuid, extensionFile, orderNumber, action) {
            return{
                uuid: uuid,
                extensionFile: extensionFile,
                orderNumber: orderNumber,
				action: action
            };
        }

        vm.formatMoney = function (value, currencySymbol) {
            return formatMoney(value, currencySymbol, vm.flyer.currencyInstance.decimalQuant,
                vm.flyer.currencyInstance.centsSeparator, vm.flyer.currencyInstance.thousandsSeparator);
        };

        vm.checkDiscountedValue = function () {
            if (vm.flyer.purchaseValue && vm.flyer.discountedValue) {
                if (parseFloat(vm.flyer.discountedValue) > parseFloat(vm.flyer.purchaseValue)) {
                    vm.flyerForm.discountedValue.$setValidity('discountGreaterThanValue', false);
                } else {
                    vm.flyerForm.discountedValue.$setValidity('discountGreaterThanValue', true);
                }
            }
        }

        vm.checkVariationDiscountedValue = function (index) {
            if (vm.flyer.details[index].purchaseValue && vm.flyer.details[index].discountedValue) {
                if (vm.flyer.details[index].discountedValue > vm.flyer.details[index].purchaseValue) {
                    vm.flyerForm['varDiscountedValue' + index].$setValidity('discountGreaterThanValue', false);
                } else {
                    vm.flyerForm['varDiscountedValue' + index].$setValidity('discountGreaterThanValue', true);
                }
            }
        }

        vm.addDetail = function () {
            vm.flyer.details.push({
                size: undefined,
                purchaseValue: undefined,
                discountedValue: undefined,
                inventory: undefined,
                quantityByPerson: undefined,
                measureList: []
            });
            addValueToColumn();
        }

        function addValueToColumn() {
            vm.measureList.forEach(function (measure) {
                vm.flyer.details[vm.flyer.details.length - 1].measureList.push({ name: measure.name, value: 0 })
            });
        }

        vm.addMeasures = function () {
            if (!vm.flyer.details.length) {
                if (!vm.flyer.measureList) {
                    vm.flyer.measureList = [];
                }

                if (vm.flyer.measureList.length) {
                    vm.flyer.measureList.push({ name: '', value: 0 });
                } else {
                    vm.flyer.measureList.push({ name: $translate.instant('flyers.new.form.weight-dimensions.height'), value: 0 });
                    vm.flyer.measureList.push({ name: $translate.instant('flyers.new.form.weight-dimensions.width'), value: 0 });
                }
            } else {
                vm.flyer.details.forEach(function (detail) {
                    if (!detail.measureList) {
                        detail.measureList = [];
                    }

                    if (detail.measureList.length) {
                        detail.measureList.push({ name: '', value: 0 });
                    } else {
                        detail.measureList.push({ name: $translate.instant('flyers.new.form.weight-dimensions.height'), value: 0 });
                        detail.measureList.push({ name: $translate.instant('flyers.new.form.weight-dimensions.width'), value: 0 });
                    }

                });
            }
            if (vm.measureList.length) {
                vm.measureList.push({ name: '' });
            } else {
                vm.measureList.push({ name: $translate.instant('flyers.new.form.weight-dimensions.height') });
                vm.measureList.push({ name: $translate.instant('flyers.new.form.weight-dimensions.width') });
            }

        }

		function findIndex(value){
			return value.name == this;
		}

        function mapMeasures(nameMeasure) {
            if (!vm.flyer.details.length) {
                if (vm.flyer.measureList) {
                    if (vm.measureList.findIndex(findIndex, nameMeasure) < 0 || nameMeasure === '') {
                        vm.measureList.push({ name: nameMeasure });
                    }

                }
            } else {
                vm.flyer.details.forEach(function (detail) {
                    if (detail.measureList) {
                        if (vm.measureList.findIndex(findIndex, nameMeasure) < 0 || nameMeasure === '') {
                            vm.measureList.push({ name: nameMeasure });
                        } else {
                            if(detail.measureList.map(function(el) {return el.name}).indexOf(nameMeasure) == -1) {
                                detail.measureList.push({"name": nameMeasure, "value": "0"})
                            }
                        }

                    } else {
                        detail.measureList = [];
                        detail.measureList.push({"name": nameMeasure, "value": "0"});
                    }
                });
            }
        }

        vm.removeMeasure = function (index) {
            if (vm.measureList.length > 1) {
                vm.measureList.splice(index, 1);
                if (!vm.flyer.details.length) {
                    vm.flyer.measureList.splice(index, 1);
                } else {
                    vm.flyer.details.forEach(function (detail) {
                        detail.measureList.splice(index, 1);
                    });
                }
            }
        }

        vm.addVariation = function () {
            $uibModal.open({
                templateUrl: 'app/admin/flyers/flyers-add-size-variation.html',
                controller: function (selectedSizes, $uibModalInstance) {
                    var vm = this;

                    vm.tab = 'classic';
                    vm.sizes = getClassicSizes();
                    vm.selectedSizes = selectedSizes;

                    vm.changeTab = function(tab) {
                        vm.tab = tab;
                        switch (tab) {
                            case 'classic':
                                vm.sizes = getClassicSizes();
                                break;
                            case 'juvenileChild':
                                vm.sizes = getJuvenileChildSizes();
                                break;
                            case 'shorts':
                                vm.sizes = getShortSizes();
                                break;
                            case 'plusSize':
                                vm.sizes = getPlusSizes();
                                break;
                            case 'others':
                                vm.sizes = vm.sizes = getOtherSizes();
                                break;
                            default:
                                break;
                        }
                    }

                    vm.checkSize = function (size, evt) {
                        if (evt.target.checked) {
                            vm.selectedSizes.push(size);
                        } else {
                            var index = vm.selectedSizes.indexOf(size);
                            vm.selectedSizes.splice(index, 1);
                        }
                    }

                    vm.isChecked = function (size) {
                        return selectedSizes.includes(size)
                    }

                    vm.closeModal = function () {
                        $uibModalInstance.dismiss('cancel');
                    }

                    vm.ok = function () {
                        $uibModalInstance.close(vm.selectedSizes);
                    }
                },
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    selectedSizes: function () {
                        return angular.copy(vm.selectedSizes);
                    }
                }
            }).result.then(function (response) {
                if (response) {
                    // Add new items
                    angular.forEach(response, function (newSize) {
                        if (!vm.selectedSizes.includes(newSize)) {
                            vm.flyer.details.push({
                                size: newSize,
                                purchaseValue: undefined,
                                discountedValue: undefined,
                                inventory: undefined,
                                quantityByPerson: undefined,
                                dimension: undefined,
								imgFlyerId: undefined,
                                measureList: []
                            })
                        }
                    })

                    // Remove old items
                    angular.forEach(vm.selectedSizes, function (currentSize) {
                        if (!response.includes(currentSize)) {
                            _.remove(vm.flyer.details, function (detail) {
                                return detail.size === currentSize;
                            });
                        }
                    })
                    vm.selectedSizes = response;
                }
            });
        }

        vm.removeVariation = function (index) {
            _.remove(vm.selectedSizes, function (size) {
                return vm.flyer.details[index].size === size;
            })
            vm.flyer.details.splice(index, 1);
        }

        // private methods
        $scope.$watch('vm.mainMap.zoom', function (newVal) {
            if (newVal > 16 && vm.firstZoom) {
                vm.mainMap.zoom = 16;
                vm.firstZoom = false;
            }
        });

        function saveFlyer() {
            // Valid dates

            if (!vm.enableIndiviualPrice) {
                if (!vm.flyer.details || vm.flyer.details.length === 0) {
                    toastr.warning('Adicione ao menos uma variação');
                    return;
                }
                vm.flyer.ean = null;
            }

            if (vm.flyer.startDate) {
                // Compare the day
                if (vm.flyer.expirationDate) {
                    if (moment(vm.flyer.startDate, DATE_FORMAT).isSameOrAfter(moment(vm.flyer.expirationDate, DATE_FORMAT))) {
                        toastr.warning($translate.instant('error.flyerStartDateGreaterThanOrEqualsToExpirationDate'));
                        return;
                    }
                }
            }

            if (vm.measureList.length) {
                var obj = {};
                for ( var i=0, len=vm.measureList.length; i < len; i++ ) {
                    obj[vm.measureList[i]['name']] = vm.measureList[i];
                }
                var unique = new Array();
                for ( var key in obj ) {
                    unique.push(obj[key]);
                }
                if (unique.length != vm.measureList.length) {
                    toastr.error($translate.instant('error.flyerDuplicateMeasures'));
                    vm.isSaving = false;
                    return;
                }
            }

            if (vm.flyer.measureList && vm.flyer.measureList.length) {
                vm.flyer.measureList.forEach(function (measure, index) {
                    measure.name = vm.measureList[index].name;
                });
            } else {
                vm.flyer.details.forEach(function (detail) {
                    if (detail.measureList) {
                        detail.measureList.forEach(function (measure, index) {
                            measure.name = vm.measureList[index].name;
                        });
                    }
                });
            }


            vm.existsSalesPoint = false;

            var flyerToSave = angular.copy(vm.flyer);
            flyerToSave.id = vm.isEdit ? vm.flyer.id : undefined;
            flyerToSave.isPublished = vm.flyer.isPublished ? vm.flyer.isPublished : false;

            flyerToSave.startDate = vm.flyer.startDate ? moment(vm.flyer.startDate, DATE_FORMAT).toDate() : now;
            flyerToSave.expirationDate = vm.flyer.expirationDate ? moment(vm.flyer.expirationDate, DATE_FORMAT).toDate() : undefined;
            flyerToSave.validityTypeDate = !!vm.flyer.expirationDate;
            flyerToSave.storeId = $localStorage.defaultStore.storeId;
            flyerToSave.salesPoint = [];
            flyerToSave.currency = vm.flyer.currencyInstance.key;
            flyerToSave.gender = parseInt(vm.flyer.gender);
            flyerToSave.tags = vm.flyer.tags.map(function (tag) {
                return tag['text'];
            });

            flyerToSave.discountedValue = flyerToSave.discountedValue > 0 ? flyerToSave.discountedValue : null;
            flyerToSave.brand = vm.flyer.brand.name;
			if (document.getElementById('color') && vm.searchColorText != "" && vm.flyer.color == null) {
				toastr.warning("A cor informada não está cadastrada")
				return;
			}
			if (vm.searchColorText != "" && vm.flyer.color != null){
				flyerToSave.color = vm.flyer.color.name;
            }

            vm.mainMap.markers.forEach(function (point) {
                if (point.clicked) {
                    vm.existsSalesPoint = true;
                    var newPoint = point.pointlocation;
                    newPoint.storeId = $localStorage.defaultStore.storeId;
                    newPoint.location = {
                        type: "POINT",
                        "x": point.latitude,
                        "y": point.longitude,
                        coordinates: [point.latitude, point.longitude]
                    };
                    flyerToSave.salesPoint.push(newPoint);
                }
            });
            if (vm.existsSalesPoint) {
                $q.all(flyerToSave.salesPoint).then(function () {
                    if (vm.isEdit) {
                        FlyersService.updateFlyer(flyerToSave).then(function () {
                            var params = {'brand': flyerToSave.brand, 'category': flyerToSave.categoryKey};
                            $state.go('flyers', params);
                            toastr.success($translate.instant('flyers.messages.update.success'));
                            vm.isSaving = false;
                        }, function (error) {
                            vm.isSaving = false;

                            if (error.status == 400) {
                                var errorValue;
                                if (error.data.data.message.includes('duplicatedEANInDatabase')) {
                                    errorValue = error.data.data.data.join(", ");
                                } else if (error.data.data.message.includes('duplicatedSizeInTable')) {
                                    errorValue = error.data.data.data.join(", ");
                                } else if (error.data.data.message.includes('flyers.messages.published.warning')) {
                                    toastr.warning($translate.instant(error.data.data.message));
                                } else {
                                    errorValue = error.data.data.data;
                                }
                                toastr.error($translate.instant(error.data.data.message, { value: errorValue }));
                            } else {
                                toastr.error($translate.instant('flyers.messages.update.error.general'));
                            }

                        });
                    } else {
                        FlyersService.createFlyer(flyerToSave).then(function () {
                            var params = {'brand': flyerToSave.brand, 'category': flyerToSave.categoryKey};
                            $state.go('flyers', params);
                            toastr.success($translate.instant('flyers.messages.create.success'));
                            vm.isSaving = false;
                        }, function (error) {
                            vm.isSaving = false;
                            if (error.status == 400) {
                                var errorValue;
                                if (error.data.message.includes('duplicatedEANInDatabase')) {
                                    errorValue = error.data.data.join(", ");
                                } else if (error.data.message.includes('flyers.messages.published.warning')) {
                                    toastr.warning($translate.instant(error.data.message));
                                } else {
                                    errorValue = error.data.data;
                                }
                                toastr.error($translate.instant(error.data.message, { value: errorValue }));
                            } else {
                                toastr.error($translate.instant('flyers.messages.create.error.general'));
                            }

                        });
                    }

                });
            } else {
                vm.isSaving = false;
                toastr.error("Você deve selecionar pelo menos um ponto de venda.");
            }
        }

        function loadFlyer(flyerId) {
            FlyersService.getFlyer(flyerId)
                .then(function (response) {
                    vm.flyer = response.data;
                    if (!vm.colors.includes(vm.flyer.color)) {
                        vm.colors.push(vm.flyer.color);
                    }

					if (vm.flyer.demoVideo){
						vm.enableDemoVideo = true;
					}
                    vm.flyer.currencyInstance = vm.currencyConfigurations[vm.flyer.currency];
                    vm.flyer.gender = vm.flyer.gender ? vm.flyer.gender.toString() : undefined;
                    if(vm.flyer.dimension && vm.flyer.dimension.weight != null) {
                        vm.enableDimension = true;
                    }

                    if (vm.flyer.tags && vm.flyer.tags.length > 0) {
                        vm.enableTags = true;
                    } else {
                        vm.flyer.tags = [];
                    }

                    if (vm.flyer.details && vm.flyer.details.length > 0) {
                        angular.forEach(vm.flyer.details, function (detail) {
                            vm.selectedSizes.push(detail.size);
                        })

                        vm.flyer.details.forEach(function (detail) {
                            vm.enableIndiviualPrice = false;

                            if(detail.dimension && detail.dimension.weight != null && !vm.enableDimension) {
                                vm.enableDimension = true;
                            }
                            if(detail.measureList && detail.measureList.length) {
                                vm.enableMeasure = true;
                                detail.measureList.forEach(function(measure){
                                    mapMeasures(measure.name);
                                })
                            } else {
                                detail.measureList = [];
                            }
                        });

                    } else {
                        vm.flyer.details = [];
                    }

                    if(vm.flyer.measureList && vm.flyer.measureList.length) {
                        vm.enableMeasure = true;
                        vm.flyer.measureList.forEach(function(measure){
                            mapMeasures(measure.name);
                        })
                    }

                    if (vm.flyer.bulkQuantity) {
                        vm.enableBatchSale = true;
                    }

                    if (vm.flyer.rulesForUse) {
                        vm.enableRules = true;
                    }

                    if (vm.flyer.startDate || vm.flyer.expirationDate) {
                        vm.enableSchedules = true;
                        if (vm.flyer.startDate) {
                            vm.flyer.startDate = moment(vm.flyer.startDate).format(DATE_FORMAT)
                        }
                        if (vm.flyer.expirationDate) {
                            vm.flyer.expirationDate = moment(vm.flyer.expirationDate).format(DATE_FORMAT);
                            vm.flyer.validityTypeDate = true;
                        }
                        $timeout(function () {
                            vm.changeSchedules();
                        }, 200)
                    }
					vm.loadImages();
                    BrandService.findBrand(vm.flyer.brand).then(function (response) {
                        vm.brands = response.data;
                    });

                    ShowcasesService.isFlyerPublished(flyerId)
                    .then(function (response) {
                        vm.flyer.isPublished = response.data;
                    });

                    loadData();
                }, function () {
                    toastr.success($translate.instant('flyers.messages.load.success'));
                });
        }

        vm.verifyPurchaseNotValid = function() {
            if (vm.flyer.purchaseValue === undefined || vm.flyer.purchaseValue === null) {
                vm.flyerForm.purchaseValue.$setValidity('pattern', true);
                return;
            }

            vm.flyerForm.purchaseValue.$setValidity('pattern', !(vm.flyer.purchaseValue <= 0));
        }

        vm.verifyNotValid = function(index, value) {
            if (value === undefined || value === null) {
                vm.flyerForm['varPurchaseValue' + index].$setValidity('pattern', true);
                return;
            }

            vm.flyerForm['varPurchaseValue' + index].$setValidity('pattern', !(value <= 0));

            // if (!value || isNaN(value)) {
            //     vm.flyerForm['varPurchaseValue' + index].$error.pattern = true;
            //     return true;
            // }

            // vm.flyerForm['varPurchaseValue' + index].$error.pattern = value <= 0;
            // console.log(index + ' ' + value + ' = ' + vm.flyerForm['varPurchaseValue' + index].$error.pattern);
            // return value <= 0;
        }

        function loadData() {
            FlyerCategoryService.listCategoriesByCurrentStore($translate.use(), $localStorage.defaultStore.storeId).then(function (response) {
                vm.categories = response.data;
                sortCategoriesByName();
                if (vm.isEdit) {
                    vm.fullCategory = _.find(vm.categories, function (category) {
                        return category.key === vm.flyer.categoryKey;
                    })
                }
                selectedColorChange();
                vm.isLoading = false;
                initImageHandle();
            });

            StoreService.getSalesPoints($localStorage.defaultStore.storeId).then(function (response) {
                convertToMarkers(response.data);
                if (vm.isEdit) {
                    vm.allSalesPoint = vm.flyer.salesPoint.length === vm.mainMap.markers.length;
                    if (vm.allSalesPoint) {
                        vm.checkAllMarkers();
                    } else {
                        vm.flyer.salesPoint.forEach(function(salesPoint) {
                            for (var i = 0; i < vm.mainMap.markers.length; i++) {
                                if (salesPoint.salesPointId == vm.mainMap.markers[i].pointlocation.salesPointId) {
                                    vm.mainMap.markers[i].clicked = true;
                                    vm.mainMap.markers[i].options.icon = ICON_ACTIVE;
                                }
                            }
                        });
                    }
                } else {
                    vm.allSalesPoint = true;
                    vm.checkAllMarkers();
                }
            });

            BrandService.findBrand("").then(function (response) {
                vm.brands = response.data;
                setBrandObjectOnForm();
            });

        }

        function newBrand(name) {
            BrandService.createBrand(name).then(function (response) {
                toastr.success($translate.instant('brand.messages.success.create'));
                vm.flyer.brand = response.data
				var defer = $q.defer();
				defer.resolve(response.data)
                vm.brands = response.data.slice();
            });
        }

        function querySearchBrand(query) {
            var defer = $q.defer();
            BrandService.findBrand(query === undefined ? "" : query).then(function (response) {
                defer.resolve(response.data)
                vm.brands = response.data.slice();
            }, function (_) {
                deferred.resolve([]);
            });
            return defer.promise;
        }

        function querySearchCategory(query) {
            var results = query ? vm.categories.filter(createFilterFor(query)) : vm.categories;
            var defer = $q.defer();
            defer.resolve(results);
            return defer.promise;
        }

        function createFilterFor(query) {
            var lowercaseQuery = query.toLowerCase();

            return function filterFn(category) {
              return (category.title.toLowerCase().indexOf(lowercaseQuery) === 0);
            };
        }

        function newCategory(name) {
            if (!vm.isCreatingCategory) {
                vm.isCreatingCategory = true;
                var key = name.toUpperCase().replace(/ /g, "_");
                var category = {
                    key: key,
                    langKey: $translate.use(),
                    title: name
                };
                var categoryDTO = { key: key, languages: [{ langKey: $translate.use(), title: name }], categoryGroup: "OUTROS" };

                FlyerCategoryService.createCategoryByStore(categoryDTO).then(function (response) {
                    toastr.success($translate.instant('categorys.messages.new.success'));
                    vm.isCreatingCategory = false;

                    category.ownerKey = response.data.ownerKey;

                    vm.categories.push(category);
                    sortCategoriesByName();
                    selectedCategoryChange(category);

                }, function () {
                    toastr.error($translate.instant('categorys.messages.new.error'));
                    vm.isCreatingCategory = false;
                });
            }
        }

        function selectedCategoryChange(category) {
            if (category) {
                vm.flyer.categoryKey = category.key;
                vm.fullCategory = category;
            }
        }

        function sortCategoriesByName() {
            vm.categories = vm.categories.sort(function(a, b) {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                }
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }

        function selectedColorChange() {

            ColorService.findColor("").then(function (response) {
				var defer = $q.defer();
				defer.resolve(response.data)
                vm.colors = response.data.slice();
                var index = vm.colors.findIndex(function (value) {
                    return value.name == vm.flyer.color;
                });
                vm.flyer.color =  vm.colors[index];
            });
        }

        function newColor(name) {
            ColorService.createColor(name).then(function (response) {
                toastr.success($translate.instant('color.messages.success.create'));
                vm.flyer.color = response.data
				var defer = $q.defer();
				defer.resolve(response.data)
                vm.colors = response.data.slice();
            });
        }


//		$scope.ok = function() {
//		  document.querySelector('md-virtual-repeat-container').classList.add('ng-hide');
//		};
//
//		$scope.searchTextChange = function() {
//		  document.querySelector('md-virtual-repeat-container').classList.remove('ng-hide');
//		};

        function querySearchColor(query) {
			vm.searchColorText = vm.capitalize(query);
			query = vm.searchColorText;
            var defer = $q.defer();
            ColorService.findColor(query === undefined ? "" : query).then(function (response) {
                defer.resolve(response.data)
                vm.colors = response.data.slice();
            }, function (_) {
                deferred.resolve([]);
            });
            return defer.promise;
        }

        function selectedBrandChange(brand) {
            // console.log('Brand changed to ' + JSON.stringify(brand));
        }

        function convertToMarkers(points) {
            points.forEach(function (point) {
                vm.mainMap.markers.push({
                    pointlocation: {
                        name: point.name,
                        country: point.country,
                        state: point.state,
                        neighborhood: point.neighborhood,
                        number: point.number,
                        address: point.address,
                        city: point.city,
                        salesPointId: point.id,
                        postalCode: point.postalCode,
                        contactPhone: point.contactPhone,
                        storeId: vm.store
                    },
                    latitude: point.coordinate.latitude,
                    longitude: point.coordinate.longitude,
                    id: point.id,
                    clicked: false,
                    options: {
                        icon: ICON_INACTIVE
                    }
                });
            });
            if (points.length === 1) {
                vm.allSalesPoint =
                vm.checkAllMarkers();
            }
        }

        function initImageHandle() {
                $timeout(function () {
                    angular.element(document.querySelector('#imgInput1')).on('change', handleFileSelect);
                    angular.element(document.querySelector('#imgInput2')).on('change', handleFileSelect);
                    angular.element(document.querySelector('#imgInput3')).on('change', handleFileSelect);
                    angular.element(document.querySelector('#imgInput4')).on('change', handleFileSelect);
                }, 200)
        }

		function handleFileSelect(e) {
			var id = e.currentTarget.id.replace('imgInput','');
            var file = e.currentTarget.files[0];
            if ((file.size / 1024) > MAX_IMAGE_SIZE) {
                toastr.error($translate.instant('flyers.messages.create.image.sizeError'));
            } else {
                var reader = new FileReader();
	                reader.onload = function (e) {
	                $scope.PreviewImage = e.target.result;
	                $scope.$apply();
	                vm.imgsFlyers.forEach(function (imgFlyer) {
	                    if (imgFlyer.id === id) {
	                        imgFlyer.src = e.target.result;
	                        imgFlyer.isDefault = false;
							imgFlyer.isModified = true;
	                    }
	                });
	                vm.moveImageFlyerToFirstIndex();
                    /*$scope.$apply(function () {
                        vm.imageUpload = evt.target.result;
                    });*/
                };
                reader.readAsDataURL(file);
            }
        }

        function checkExpirationDate() {
            if (vm.flyer.expirationDate) {
                if (moment(vm.flyer.startDate, DATE_FORMAT).isSameOrAfter(moment(vm.flyer.expirationDate, DATE_FORMAT))) {
                    /** returns false if the expirationDate is before startDate */
                    return false;
                }
                /** returns true if the expirationDate is ok (the expirationDate is after startDate) */
                return true;
            }
        }

        function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });
        }

        function setBrandObjectOnForm() {
            vm.brands.forEach(function (brand){
                if(brand.name === vm.flyer.brand) {
                    vm.flyer.brand = brand
                    return;
                }
            });
        }

        vm.flyersSelectImageDetailModal = function(detail, index) {
            $uibModal.open({
                templateUrl: 'app/admin/flyers/flyers-select-image-detail.html',
                controller: function (imgsFlyers, imgFlyerId, size, $uibModalInstance) {
                    var vm = this;
                    vm.imgsFlyers = imgsFlyers;
                    vm.imgFlyerId = imgFlyerId;
                    vm.size = size;
                    vm.close = close;

                    vm.sortableOptionsDetail = {
                        disabled: true
                    };

                    vm.selectImg = function(imgsFlyer) {
                        if (!imgsFlyer.isDefault) {
                            if(vm.imgFlyerId === imgsFlyer.id) {
                                vm.imgFlyerId = null;
                            } else {
                                vm.imgFlyerId = imgsFlyer.id;
                            }
                        }
                    }

		            vm.getCountDefaultImageFlyer = function () {
			            var count = 0;
			            vm.imgsFlyers.forEach(function (img){
			                if(img.isDefault) {
			                    count++;
			                }
			            });
			            return count;
			        }

                    function close() {
                        $uibModalInstance.close(vm.imgFlyerId);
                    }

                },
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                keyboard  : false,
                resolve: {
                    imgsFlyers: function () {
                        return angular.copy(vm.imgsFlyers);
                    },
                    size: function () {
                        return detail.size;
                    },
                    imgFlyerId: function () {
                        return detail.imgFlyerId;
                    }
                }
            }).result.then(function(selectImgId) {
                vm.addOrUpdateImgDetail(selectImgId, index)

            });
        }

        vm.openInputImage = function (evt, id) {
            document.getElementById('imgInput' + id).click();
        }

        vm.changeDemoVideo = function () {
            if (!vm.enableDemoVideo) {
                vm.flyer.demoVideo = null;
            }
        }

        vm.getCountDefaultImageFlyer = function () {
            var count = 0;
            vm.imgsFlyers.forEach(function (img){
                if(img.isDefault) {
                    count++;
                }
            });
            return count;
        }

        vm.moveImageFlyerToFirstIndex = function () {
            var imgsDefault = [];
            var imgs = [];
            $timeout(function () {
                for (var i = 0; i < vm.imgsFlyers.length; i++) {
                    vm.imgsFlyers[i].isThumbnail = false;
                    if (vm.imgsFlyers[i].isDefault) {
                        imgsDefault.push(vm.imgsFlyers[i]);
                    } else {
                        imgs.push(vm.imgsFlyers[i]);
                    }
				}

                vm.imgsFlyers = imgs;
				for (var j = 0; j < imgsDefault.length; j++) {
					vm.imgsFlyers.push(imgsDefault[j]);
				}
				if (vm.imgsFlyers[0].isDefault){
					vm.imgsFlyers[0].isThumbnail = false;
				}else{
					vm.imgsFlyers[0].isThumbnail = true
				}
            }, 500);
        }

       vm.removeImgFlyer = function (id) {
           vm.imgsFlyers.forEach(function (imgFlyer) {
                if (imgFlyer.id === id) {
                    imgFlyer.src = vm.srcImgDefault;
                    imgFlyer.isDefault = true;
                    imgFlyer.isThumbnail = false;
                }
            });
           vm.removeReferenceImgDetail(id);
            vm.moveImageFlyerToFirstIndex();
        }

        vm.addOrUpdateImgDetail = function (imgFlyerId, index) {
            vm.flyer.details[index].imgFlyerId = imgFlyerId;

//			vm.flyer.details.forEach(function (detail) {
//                if (detail.size === sizeDetail) {
//                    detail.imgFlyerId = imgFlyerId;
//                }
//            });
        }

        vm.removeReferenceImgDetail = function (imgFlyerId) {
            vm.flyer.details.forEach(function (detail) {
                if (detail.imgFlyerId === imgFlyerId) {
                    detail.imgFlyerId = null;
                }
            });
        }

        vm.getIndexImageById = function (imgFlyerId) {
            for (var i = 0; i < vm.imgsFlyers.length; i++) {
                if (vm.imgsFlyers[i].id === imgFlyerId) {
                    return parseInt(i);
                }
            }
        }
    }

})();
