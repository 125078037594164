(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('CustomersImportController', CustomersImportController);

    /** @ngInject */
    function CustomersImportController($scope, $state, Upload, toastr, $translate, CustomerService, $window) {
        var format = "L";
        var vm = this;

        vm.filteredCustomers = []
        vm.currentPage = 1;
        vm.numPerPage = 10;
        vm.maxSize = 5;

        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.uploaded = false;
        vm.allStatusOk = false;
        vm.customersImported = [];
        vm.isUploading = false;
        vm.isSaving = false;

        vm.file = null;

        vm.getDownloadLinkToTemplate = function () {
            return $window.location.protocol + "//" + $window.location.host + '/content/docs/template-import-clients.xlsx';
        }

        vm.save = function () {
            vm.isSaving = true;
            if (vm.customersImported.length > 0 && vm.allStatusOk) {
                CustomerService.saveImportedCustomers(vm.customersImported).then(function (response) {
                    toastr.success($translate.instant('customer.import.successfullyImport'));
                    vm.uploaded = false;
                    vm.allStatusOk = false;
                    vm.isSaving = false;
                    $state.go('customers');
                }, function (error) {
                    toastr.error($translate.instant('customer.import.error.errorOnSaveImport'));
                    vm.isSaving = false;
                });
            }
            vm.isSaving = false;
        }

        function orderByErrors() {
            if (vm.customersImported.length > 0) {
                vm.customersImported.sort(function (a, b) {
                    //Inverted sort to make all row with error go to top
                    return b.errors.length - a.errors.length;
                });
            }
        }

        vm.uploadFile = function (file, errFiles) {
            vm.file = file;
            vm.errFiles = errFiles && errFiles[0];


            if (vm.errFiles) {
                if (errFiles[0].$error == 'pattern') {
                    toastr.error($translate.instant('customer.import.error.invalidType'));
                    return;
                }
            }

            if (file) {
                vm.isUploading = true;

                file.upload = Upload.upload({
                    url: 'admin/api/upload-customers-table',
                    data: {
                        file: file
                    }
                });

                file.upload.then(function (response) {
                    if (response.data) {
                        vm.customersImported = response.data;
                        vm.allStatusOk = true;
                        // vm.customersImportedErrors = [];
                        vm.customersImported.forEach(function (element) {
                            if (element.errors.length > 0) {
                                vm.allStatusOk = false;
                                // vm.customersImportedErrors.push(element);
                            }
                        });

                        // vm.customersImported = vm.customersImportedErrors;

                        vm.isUploading = false;

                        orderByErrors();

                        if (vm.customersImported.length > 0) {
                            vm.changePage();
                        }
                    } else {
                        toastr.warning($translate.instant('customer.import.error.tryAgain'));
                        vm.isUploading = false;
                        vm.customersImported = [];
                    }

                }, function (response) {
                    vm.customersImported = [];
                    vm.isUploading = false;
                    toastr.error($translate.instant('customer.import.error.errorOnUploadFile'));
                });

            }
        }

        vm.cancel = function () {
            vm.uploaded = false;
            vm.allStatusOk = false;
            $state.go('customers');
        }

        vm.changePage = function () {
            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;
            vm.filteredCustomers = vm.customersImported.slice(begin, end);
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;
        }

        // Workaround to fix navigation sidebar
        $(document).ready(function () {
            // if (!document.getElementsByClassName('slimScrollDiv').length) {
            //     setTimeout(function () {
            //         document.getElementsByClassName('slimScrollDiv')[0].setAttribute('style', 'position: relative; width: auto; height: 690px;');
            //     }, 1000);
            // } else {
            //     document.getElementsByClassName('slimScrollDiv').setAttribute('style', 'position: relative; width: auto; height: 690px;');
            // }

            // if (!document.getElementById('side-menu').length) {
            //     setTimeout(function () {
            //         document.getElementById('side-menu').setAttribute('style', 'height: 690px; width: auto;');
            //     }, 500);
            // } else {

            // }

        });

    }
})();
