(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .value('FlyersCurrencyValue', {
            EURO: "€",
            DOLLAR: "$",
            REAL: "R$",
            LIBRA: "£",
            NOVO_SOL_PERUANO: "S/",
            DINAR_KUWAITIANO: "د.ك",
            DIRHAM_DOS_EMIRADOS_ARABES_UNIDOS: "د.إ",
            PESO_MEXICANO: "Mex$",
            PESO_DOMINICANO: "RD$",
            PESO_FILIPINO: "₱",
        });
})();
