(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .controller('CommunicationController', CommunicationController)
        .filter('parseUrl', function () {
            var url_match = /((http|ftp|https):\/\/)*([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*)/gim

            return function (text) {
                if (text.match(url_match)) {
                    text = text.replace(url_match, "<a href=\"https://$3$4\" target=\"_blank\">$3$4</a>")
                }

                return text
            }
        })
        .filter('messageTextFormat', function() {
            return function(text) {
                var url_match = /((http|ftp|https):\/\/)*([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*)/gim

                if (text.match(url_match)) {
                    return text;
                }
                return text.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g,'<b>$1</b>')
                    .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
                    .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
                    .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
                    .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>');
            };
        });

    function CommunicationController(ChatService, communicationConstants, $uibModal, WatiService, toastr, $scope, $window, EmojisConstants, FlyersService,
        $localStorage, MessengerService, FacebookService, $timeout, $translate, SocialMediaService, $state, CustomerService, NoteTagService,
        ChatTicketService, UserService, StoreService, ChatUnreadMessageService, FBAuthModalFactory, WsService, CheckCEPService, UserEngagementService, FlyerCategoryService, Principal, FlyersCurrencyValue,
                                     $stateParams, NotificationService, $rootScope, AudioModalFactory, ChatCommerceService, IndexedDBCommunication,
                                     MediaDeviceService, ImageManagerService) {
        var vm = this;

        vm.allChatsConnected = false;
        vm.isLoadingConnection = true;
        vm.loadingConversations = true;
        vm.loadingMoreConversations = false;
        vm.loadingMessages = false;
        vm.showSkeleton = true;
        vm.loadingCustomerInfos = true;
        vm.sendMessageBtnLoading = false;
        vm.isSendLocation = false;
        vm.text = '';
        vm.gumStream = undefined;
        vm.recorder = undefined;
        vm.input = undefined;
        vm.discarded = false;

        vm.mobileFlow = {
            'listConversations': true,
            'chatConversation': false,
            'customerInfos': false
        }

        vm.notes = [];
        vm.tags = [];
        vm.stickers = [];
        vm.stickersApi = '';
        vm.stickersPageNumber = 0;

        vm.emojis = EmojisConstants.EMOJIS;
        vm.currentEmojiFilter = 'all';
        vm.searchedEmojiWord = '';
        vm.filteredEmojis = {};
        vm.emojiFilters = EmojisConstants.FILTERS;

        vm.supportedFilesType = communicationConstants.SUPPORTED_FILES_TYPES;
        vm.supportedImagesType = communicationConstants.SUPPORTED_IMAGES_TYPES;

        vm.newNoteText = "";
        vm.newTagText = ""
        vm.isWritingNote = false;
        vm.isEditingNote = false;
        vm.currentlyEditingNote = 0;
        vm.allWatiMessages = 0;
        vm.allInstaMessages = 0;
        vm.allMessengerMessages = 0;
        vm.attachmentTypes = ['image', communicationConstants.MESSAGES_TYPES.STORYMENTION];
        vm.allMessages = vm.allWatiMessages + vm.allInstaMessages + vm.allMessengerMessages;
        vm.days = [$translate.instant('communication.days.sunday'),
        $translate.instant('communication.days.monday'),
        $translate.instant('communication.days.tuesday'),
        $translate.instant('communication.days.wednesday'),
        $translate.instant('communication.days.thursday'),
        $translate.instant('communication.days.friday'),
        $translate.instant('communication.days.saturday')]
        var RESERVED_TYPE = 'RESERVED';
        var FORMAT_DATE = 'DD/MM/YYYY';
        var FORMAT_TIME = 'HH:mm';

        vm.loadingUserInfo = false;
        vm.loadingUserCategoryStatistics = false;
        vm.loadingUserOfferEngagements = false;

        vm.currentPage = 1;
        vm.numPerPage = 5;
        vm.maxSize = 5;
        vm.totalElements = 0;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        vm.usersAttendants = [];

        vm.status = {
            'NEW': 'Novo',
            'OPENED': 'Aberto',
            'PENDING': 'Pendente',
            'RESOLVED': 'Resolvido',
            'EXPIRED': 'Expirado'
        }

        vm.isWatiConnected = false;
        vm.fbConnected = false;

        vm.syncSocialPromise = {
            wati: false,
            instagram: false,
            messenger: false
        }

        getManagerUser();

        function getManagerUser() {
            Principal.identity().then(function (account) {
                if (account) {
                    vm.account = angular.copy(account);
                }
            });
        }

        vm.proxy = new Proxy(vm.days, {
            get: function (target, prop) {
                if (!isNaN(prop)) {
                    prop = parseInt(prop, 10);
                    if (prop < 0) {
                        prop += target.length;
                    }
                }
                return target[prop];
            }
        });

        var oneHourInMilliseconds = 3600000
        var oneMinuteInMiliseconds = 60000
        vm.startingTime = 24;
        vm.interval = undefined;
        vm.openedMessages = [];
        vm.attemptToSendMedia = 0;
        vm.chatConfiguration = {};

        vm.pageNumber = 1;
        vm.currentUser = "";
        vm.currentCustomer = {};
        vm.conversations = {};

        vm.cursors = { 'INSTAGRAM': null, 'MESSENGER': null };
        vm.file = {};
        vm.imagesToSend = [];
        var chunkSize = 534 * 1000; // 525KB em bytes

        vm.myInstagramId = "";
        vm.conversations = new Map();

        vm.conversationsList = [];
        vm.currentConversation = ("", {});
        vm.currentConversation.time = vm.startingTime * oneHourInMilliseconds;
        vm.currentSocialMedia = communicationConstants.ALL;
        vm.messenger = {};

        vm.isEditMode = false;
        vm.maskLocale = '99/99/9999';
        vm.customerEdit = {}

        var storeId = $localStorage.defaultStore.storeId;

        vm.currentStore = $localStorage.defaultStore;

        vm.userManager = null;
        vm.currentAttendant = null

        vm.mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');

        vm.isMobile = detectar_mobile();

        vm.searchText = "";

        vm.socialMediasOptions = [
            {
                tabId: 'WATI',
                title: 'Whatsapp',
                position: 2,
                numberOfMessages: function() { return vm.allWatiMessages },
                connected: function() {return vm.isWatiConnected}
            },
            {
                tabId: 'INSTAGRAM',
                title: 'Instagram',
                position: 1,
                numberOfMessages: function() {return  vm.allInstaMessages},
                connected: function() {return vm.fbConnected }
            },
            {
                tabId: 'MESSENGER',
                title: 'Messenger',
                position: 0,
                numberOfMessages: function() { return vm.allMessengerMessages},
                connected: function() {return vm.fbConnected}
            }
        ];

        vm.numberFilterSelected = 0;

        vm.filters = {
            status: [
                {
                    "value": "EXPIRED",
                    "label": "Expirado",
                    "id": 1
                },
                {
                    "value": "NEW",
                    "label": "Novo",
                    "id": 2
                },
                {
                    "value": "OPENED",
                    "label": "Aberto",
                    "id": 3
                },
                {
                    "value": "PENDING",
                    "label": "Pendente",
                    "id": 4
                }
            ],
            attendants: [],
            socialMedias: [],
            order: {},
            rangeDate: { startDate: null, endDate: null }
        };

        vm.selectMultipleMessages = false;
        vm.replyMessage = false;
        vm.selectedMessages = [];

        vm.messageOptions = {
            'FORWARD': {
                doAction: function(message) {
                    vm.updateMessagesToForward(message);
                    vm.selectMultipleMessages = true;
                },
            },
            'REPLY': {
                doAction: function (message) {
                    vm.replyMessage = true;
                    vm.messageToReply = message;
                }
            },
            'COPY_MESSAGE': {
                doAction: function (message) {
                    if (window.isSecureContext && navigator.clipboard) {
                        navigator.clipboard.writeText(message.text);
                    } else {
                        var dummy = document.createElement("textarea");
                        document.body.appendChild(dummy);
                        dummy.value = message.text;
                        dummy.select();
                        document.execCommand("copy");
                        document.body.removeChild(dummy);
                    }
                }
            },
            'DELETE_MESSAGE': {
                doAction: function (message) {
                    IndexedDBCommunication.getSendingFileByLocalMessageId(message.localMessageId, function (result) {
                        if (result !== null) {

                            _.remove(vm.openedMessages, function (openedMessage) {
                                return openedMessage.localMessageId === result.localMessageId;
                            });

                            IndexedDBCommunication.removeSendingFile(result.id);
                        }
                    });
                }
            }
        }

        $scope.data = {
            selectedIndex: 0,
            secondLabel: "Item Two",
            bottom: false
        };

        $scope.next = function () {
            $scope.data.selectedIndex = Math.min($scope.data.selectedIndex + 1, 2);
        };

        $scope.previous = function () {
            $scope.data.selectedIndex = Math.max($scope.data.selectedIndex - 1, 0);
        };

        vm.changeTab = function (tabName) {
            $localStorage.chatFilters.filtered.socialMedias = [];
            vm.currentSocialMedia = tabName;
            vm.conversationsList = [];
            vm.conversations = new Map();
            vm.loadingConversations = true;
            var filters = angular.copy($localStorage.chatFilters.filtered);
            filters.pageNumber = 0;
            $localStorage.chatFilters.filtered.pageNumber = 0;
            $localStorage.chatFilters.selectedFilters.pageNumber = 0;
            searchByFilter(filters);
        }

        vm.redirectTo = function (value) {
            $state.go(value)
        }

        setTimeout(function () {
            ChatCommerceService.getChatConfiguration().then(function (result) {
                vm.chatConfiguration = result.data;
            });
            IndexedDBCommunication.updateSendingFilesByStatus('CANCELED');
            verifyAllChatsConnected().finally(function () {
                WsService.getInfo().then(function (result) {
                    vm.wsToken = result.token;

                    ChatService.connectWS(function () {
                        vm.fbIsSubscribed = false;
                        getInfoAndSubscribeWhatsapp();
                        isFacebookConnected()
                        if (vm.conversationsList.length > 0) {
                            $localStorage.chatFilters.filtered.pageNumber = 0;
                            $localStorage.chatFilters.selectedFilters.pageNumber = 0;
                            vm.conversationsList = [];
                            vm.conversations = new Map();
                        }
                        vm.init();
                    });
                });
            });
        }, 500);

        function getFormattedFilters() {
            var filters = angular.copy(vm.filters);
            filters.order = filters.order.value ? filters.order.value : {};
            filters.attendants = filters.attendants.map(function(attendant){ return attendant.value });
            filters.status = filters.status.map(function(status){ return status.value });
            filters.socialMedias = [];
            filters.rangeDate = filters.rangeDate.startDate && filters.rangeDate.endDate ? formatDate(filters.rangeDate.startDate, filters.rangeDate.endDate) : null;
            filters.allAttendants = vm.usersAttendants.map(function(attendant) { return attendant.id });
            filters.pageNumber = vm.filters.pageNumber;
            return filters;
        }

        function formatDate(startDate, endDate) {
            var start = startDate.format('YYYY-MM-DD');
            var end = endDate.format('YYYY-MM-DD');
            return start + "->" + end;
        }

        vm.currentChatsStatus = {
            'WATI': {
                loading: false,
                failed: false,
                isConnected: false
            },
            'INSTAGRAM': {
                loading: false,
                failed: false,
                isConnected: false
            },
            'MESSENGER': {
                loading: false,
                failed: false,
                isConnected: false
            }
        };

        var conversationsPromises = [
            {
                platform: communicationConstants.SOCIAL_MEDIAS.WATI,
                payload: {
                    pageSize: 10,
                    chatPlatform: communicationConstants.SOCIAL_MEDIAS.WATI,
                    lastId: null
                }
            },
            {
                platform: communicationConstants.SOCIAL_MEDIAS.MESSENGER,
                payload: {
                    pageSize: 10,
                    chatPlatform: communicationConstants.SOCIAL_MEDIAS.MESSENGER,
                    cursor: null
                }
            },
            {
                platform: communicationConstants.SOCIAL_MEDIAS.INSTAGRAM,
                payload: {
                    pageSize: 5,
                    chatPlatform: communicationConstants.SOCIAL_MEDIAS.INSTAGRAM,
                    cursor: null,
                }
            }
        ];

        function updateNewConversations(conversations) {
            if (vm.conversationsList.length > 0) {
                var filters = angular.copy($localStorage.chatFilters.filtered);
                if (filters.order && filters.order.direction === 'ASC') {
                    vm.conversationsList = _.unionBy(vm.conversationsList, conversations, 'chatUserId');
                } else {
                    vm.conversationsList = _.unionBy(conversations, vm.conversationsList, 'chatUserId');
                }
            }
        }

        $scope.$on('$destroy', function () {
            clearInterval(vm.synchronizeChatInterval);
        });

        function synchronizeChat() {
            vm.synchronizeChatInterval = setInterval(function () {
                var filters = angular.copy($localStorage.chatFilters.filtered);
                filters.searchString = vm.searchText.trim();
                if (vm.currentSocialMedia !== 'ALL') {
                    filters.socialMedias = [vm.currentSocialMedia];
                }
                filters.connectedChats = {
                    isWATIConnected: vm.isWatiConnected,
                    isMetaConnected: vm.fbConnected
                };
                filters.pageNumber = 0;

                ChatCommerceService.synchronizeChat({
                    chatPlatform: 'WATI',
                    pageSize: 20
                });

                ChatCommerceService.getConversationsByFilter(filters).then(function (result) {
                    updateNewConversations(result.data.items);
                    vm.conversationsList.forEach(function (conversation) {
                        getProfilePicFromUrl(conversation);
                        if (vm.currentConversation 
                            && conversation.attendantId 
                            && vm.currentConversation.conversationId == conversation.conversationId) {
                            vm.currentConversation.attendantId = conversation.attendantId;
                            getCurrentAttendant();
                        }
                    });
                });
            }, 20000);
        }

        vm.init = function () {
            verifyPermission();
            vm.dontScrolled = true;
            vm.conversationHeight = window.innerHeight - 71;
            if (!$localStorage.chatFilters) {
                $localStorage.chatFilters = {
                    selectedFilters: {
                        status: vm.filters.status,
                        attendants: [],
                        socialMedias: [],
                        order: {},
                        rangeDate: { startDate: null, endDate: null },
                        pageNumber: 0
                    },
                    filtered: getFormattedFilters(),
                    numberFilterSelected: 1
                };
            }

            $localStorage.chatFilters.filtered.pageNumber = 0;
            $localStorage.chatFilters.selectedFilters.pageNumber = 0;

            vm.filters = $localStorage.chatFilters.selectedFilters;
            vm.numberFilterSelected = $localStorage.chatFilters && $localStorage.chatFilters.numberFilterSelected
                ? $localStorage.chatFilters.numberFilterSelected
                : vm.numberFilterSelected;

            if (vm.synchronizeChatInterval) {
                clearInterval(vm.synchronizeChatInterval);
            }

            synchronizeChat();
            if (vm.usersAttendants.length === 0) {
                getAttendantsForFilter();
            } else {
                isFacebookConnected();
                searchByFilter($localStorage.chatFilters.filtered);
                findConversationByConversationId();
            }

            updateUnreadMessagesBySocialMedia(communicationConstants.SOCIAL_MEDIAS.MESSENGER);
            updateUnreadMessagesBySocialMedia(communicationConstants.SOCIAL_MEDIAS.INSTAGRAM);
            updateUnreadMessagesBySocialMedia(communicationConstants.SOCIAL_MEDIAS.WATI);
        };
        verifyPermission();

        /**
         * Gets called when conversations are scrolled to bottom and updates the shown conversations to include older ones.
         */
        vm.conversationsScrolledToBottom = function () {
            if (vm.dontScrolled) return;
            vm.loadingMoreConversations = true;

            var filters = angular.copy($localStorage.chatFilters.filtered);
            if (vm.currentSocialMedia !== 'ALL') {
                filters.socialMedias = [vm.currentSocialMedia];
            }

            searchByFilter(filters);
        }

        vm.hasLoadingActive = function () {
            return vm.sendMessageBtnLoading || vm.loadingMessages || vm.loadingConversations;
        }

        function getAttendantsForFilter() {
            getAllAttendantsPromise().then(function (result) {
                vm.usersAttendants = result.data;
                Principal.identity().then(function (account) {
                    if (account) {
                        $localStorage.chatFilters.filtered = getFormattedFilters();
                        isFacebookConnected();
                        searchByFilter($localStorage.chatFilters.filtered);
                    }
                });
            })
        }

        function showSelectedConversation(conversation) {
            ChatCommerceService.getConversationByConversationId(conversation.conversationId)
                .then(function (result) {
                    vm.openConversation(result);
                    vm.loadingConversations = false;
                }, function (err) {
                    $localStorage.redirectNotificationData = null;
                    vm.loadingConversations = false;
                }
            );
        }

        function getAllChatConnectionPromise() {
            return Promise.all([WatiService.getConfigByCurrentStore(), SocialMediaService.isFacebookConnected()]);
        }

        function verifyAllChatsConnected() {
            vm.isLoadingConnection = true;
            return getAllChatConnectionPromise().then(function(results) {
                    var connectedWati = false;
                    var connectedFb = false;
                    var resultWati = results[0];
                    var resultFb = results[1];

                    if (resultWati.data.id) {
                        vm.isWatiConnected = true;
                        vm.watiSetting = resultWati.data;
                        connectedWati = true;
                    } else {
                        vm.isWatiConnected = false;
                    }

                    if (resultFb.data) {
                        vm.fbConnected = true;
                        connectedFb = true;
                        $localStorage.fb = resultFb.data;
                    }

                    vm.allChatsConnected = connectedWati || connectedFb;
                    vm.isLoadingConnection = false;
                }
            );
        }

        function isFacebookConnected() {
            SocialMediaService.isFacebookConnected().then(function (result) {
                if (result.data) {
                    vm.fbConnected = true;
                    $localStorage.fb = result.data;

                    if (!vm.fbIsSubscribed) {
                        ChatService.subscribeOnMessenger($localStorage.fb.page.pageId, subscribeOnMessenger);
                        ChatService.subscribeOnInstagram($localStorage.fb.business.igBusinessAccount.igAccountId, subscribeOnInstagram);
                        vm.fbIsSubscribed = true;
                    }

                    return;
                }
            });
        }

        vm.users = [];

        vm.attachFile = function () {
            var tag = document.querySelector('#file-upload-sheet');
            if (!tag.disabled) {
                vm.closeFeedbackPopUp();
            }
        }

        function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ia], {
                type: mimeString
            });

            return $window.URL.createObjectURL(blob);
        }

        /**
         * Executes when any person click in the button to send file.
         */
        vm.onSelectFile = function (event) {
            var file = event.target.files[0];
            if (file.size > communicationConstants.FILES.MAX_SIZE) {
                toastr.warning($translate.instant('communication.errors.sizeExceed'));
            } else {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vm.file.base64 = reader.result.split(',')[1];
                    vm.file.fullBase64 = reader.result;
                    vm.file.blob = dataURItoBlob(reader.result);
                    var setting = makeSettingByBase64(reader.result);
                    vm.file.type = setting.type;
                    vm.file.mimeType = setting.mimeType;
                    vm.file.name = file.name;
                    openModalToSendFile().then(function (file) {
                        if (vm.currentConversation.platform == communicationConstants.SOCIAL_MEDIAS.WATI) {
                            var base64 = file.base64;
                            delete file.base64;
                            delete file.fullBase64;

                            sendMediaMessageToWhatsapp(vm.currentConversation.chatUserId,
                                {
                                    data: base64,
                                    mimeType: file.mimeType,
                                    caption: file.name,
                                    fullData: file,
                                    blob: vm.file.blob
                                },
                                file.type, vm.file.name);
                            return;
                        }
                        sendMediaMessageToFacebook(file);

                    });
                };
            }
        }

        vm.onSelectImages = function (event) {
            vm.imagesToSend = [];
            var images = event.target.files;
            Array.from(images).forEach(function (file, index) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    var imageBlob = dataURItoBlob(reader.result);
                    var setting = makeSettingByBase64(reader.result);

                    var img = new Image();
                    img.onload = function (evt) {
                        var dataurl = reader.result;

                        if (img.width > 2560 || img.height > 1440) {
                            dataurl = ImageManagerService.handleImageSize(img, file);
                        }

                        vm.imagesToSend.push({
                            index: index,
                            name: file.name,
                            base64: dataurl.split(',')[1],
                            fullBase64: dataurl,
                            type: setting.type,
                            mimeType: setting.mimeType,
                            blob: imageBlob
                        });

                        if (vm.imagesToSend.length === images.length) {
                            openModalToSendImages().then(function (filesToSend) {
                                if (vm.currentConversation.platform == communicationConstants.SOCIAL_MEDIAS.WATI) {
                                    filesToSend.forEach(function (fileToSend) {
                                        sendMediaMessageToWhatsapp(vm.currentConversation.chatUserId,
                                            {
                                                data: fileToSend.base64,
                                                mimeType: fileToSend.mimeType,
                                                caption: fileToSend.name,
                                                fullData: fileToSend,
                                                blob: fileToSend.blob
                                            }, fileToSend.type, fileToSend.name);
                                    });
                                } else {
                                    filesToSend.forEach(function (fileToSend) {
                                        sendMediaMessageToFacebook(fileToSend);
                                    });
                                }
                            });
                        }
                    };
                    img.src = reader.result;
                }
            });
        }

        function assignChatTicketToMeIfNeeded() {
            ChatTicketService.assignTicketToAttendant(vm.currentConversation.chatTicketId, $localStorage.userId)
                .then(function (result) {
                    toastr.info($translate.instant('communication.info.assigned-to-you'));
                });
        }

        vm.updateMessageText = function() {
            var inputMessage = document.getElementById('message-input');
            if (inputMessage) {
                inputMessage.innerText = vm.text;
            }
        }

        vm.collectText = function(event) {
            vm.text = event.target.innerText;
        }

        vm.pasteText = function (event) {
            setTimeout(function() {
                vm.text = event.target.innerText;
                var keyup = new KeyboardEvent('keyup',{'bubbles':true});
                document.getElementById('message-input').dispatchEvent(keyup);
            }, 0);
        }

        function refreshWithTextMessage(messageToSend) {
            var userId = vm.currentConversation.chatUserId;
            var index = vm.conversationsList.findIndex(function (value) {
                return value.chatUserId == userId;
            });

            vm.openedMessages.push(messageToSend);

            vm.conversationsList = array_move(vm.conversationsList, index, 0);
            vm.conversationsList[0].lastMessage = angular.copy(vm.text);
            vm.conversationsList[0].lastMessageDate = new Date().getTime();
            vm.conversationsList[0].messageType = 'text';
            updateConversationData(vm.currentConversation.chatUserId, $localStorage.userId, vm.text);
            clearTextMessage();
            scrollToBottom();
            vm.isSendLocation = false;
            setTimeout(function () {
                document.getElementById('message-input').focus();
            });

            vm.closeMessageToReply();
        }

        function setAttendantName() {
            if (vm.chatConfiguration.enableSenderName) {
                var attendant = vm.usersAttendants.find(function (user) {
                    return user.id === $localStorage.userId;
                });
                if (attendant) {
                    vm.text = '*'.concat(attendant.firstName).concat(' ')
                        .concat(attendant.lastName.split(' ')[0]).concat(':*')
                        .concat('\n')
                        .concat('\n')
                        .concat(vm.text);
                }
            }
        }

        /**
         * Sends message to any person by number Whatsapp.
         */
        vm.sendTextMessage = function () {
            if(vm.recording) {
                vm.stopRecord();
                return
            }
            vm.discardAudio();

            if (vm.text && vm.text.length > 4096) {
                return;
            }

            if (!vm.text || (vm.text && !vm.text.trim())) {
                clearTextMessage();
                return;
            }

            if (vm.currentConversation.attendantId !== $localStorage.userId) {
                assignChatTicketToMeIfNeeded();
            }

            setAttendantName();

            var messageToSend = vm.text;
            var messageToReply = vm.messageToReply;

            var messageToSendData = {
                owner: true,
                text: messageToSend,
                type: communicationConstants.TYPES.TEXT.toLowerCase(),
                created: new Date().getTime(),
                replySourceMessage: messageToReply,
                isSending: true
            }

            refreshWithTextMessage(messageToSendData);

            switch (vm.currentConversation.platform) {
                case communicationConstants.SOCIAL_MEDIAS.INSTAGRAM:
                case communicationConstants.SOCIAL_MEDIAS.MESSENGER:
                     MessengerService.sendMessage(
                         messageToSend, vm.currentConversation.chatUserId
                     ).then(function (result) {
                         messageToSendData.isSending = false;
                     }).catch(function (error) {
                        vm.isSendLocation = false;
                        toastr.warning($translate.instant('communication.errors.facebookInstagramConfiguration'));
                    });
                    break;
                case communicationConstants.SOCIAL_MEDIAS.WATI:
                    WatiService.sendMessage({
                        number: vm.currentConversation.chatUserId,
                        text: messageToSend,
                        type: communicationConstants.TYPES.TEXT.toLowerCase(),
                        time: String(moment().valueOf() / 1000).split('.')[0],
                        attendantId: $localStorage.userId,
                        replyContextId: messageToReply ? messageToReply.id : null
                    }).then(function () {
                        messageToSendData.isSending = false;
                    }).catch(function (error) {
                        vm.isSendLocation = false;
                        if (error.status === 500) toastr.warning($translate.instant('communication.errors.problemSendingMessage'));
                        else if (error.status === 400) toastr.warning($translate.instant('communication.errors.argumentInvalid'));
                        else if (error.status === -1) toastr.error($translate.instant('communication.errors.connectionProblem'));
                        else toastr.warning($translate.instant('communication.errors.watiConfiguration'));
                    });

                    break;
            }
        }

        function makeMessageInstaOrMessenger(message, socialMedia) {
            var data = {
                id: message.id,
                owner: message.recipientId === message.to.data[0].id,
                timestamp: message.created_time,
                isServer: true
            }

            /** if message contains images or files (pdf, for example) **/
            if (message.attachments) {
                var attachment = message.attachments.data[0];
                data.platform = vm.currentConversation.platform;
                if (attachment.video_data) {
                    var resource = attachment.video_data;
                    data.data = resource.url;
                    data.type = 'video';
                } else {
                    var resource = attachment.image_data ? { type: communicationConstants.TYPES.IMAGE.toLowerCase(), data: attachment.image_data.url } : { type: communicationConstants.TYPES.FILE.toLowerCase(), data: attachment.file_url };
                    data.data = resource.data;
                    data.type = attachment.mime_type && attachment.mime_type.includes('audio') ? 'audio' : resource.type;
                }
            } else if (message.story) {
                data.platform = vm.currentConversation.platform;
                if (message.story.mention) {
                    data.data = message.story.mention.link;
                    data.type = communicationConstants.MESSAGES_TYPES.STORYMENTION;
                } else if (message.story.reply_to) {
                    data.data = message.story.reply_to.link
                    data.type = communicationConstants.MESSAGES_TYPES.STORYREPLY;
                    data.text = message.message;
                }
                vm.getImageFromUrl(data);
            } else if (message.is_unsupported) {
                data.text = $translate.instant('communication.errors.unsupportedMessage');
                data.type = communicationConstants.MESSAGES_TYPES.UNSUPPORTED;
            } else if (message.shares && message.shares.data[0].name) {
                data.text = "Carousel enviado com os produtos: " + message.shares.data[0].name;
                data.type = communicationConstants.MESSAGES_TYPES.TEXT;
            } else if (message.shares && message.shares.data[0].link) {
                data.type = communicationConstants.MESSAGES_TYPES.STICKER;
                data.api = message.shares.data[0].link;
            }
            else {
                data.text = message.message;
                data.type = communicationConstants.MESSAGES_TYPES.TEXT;
            }
            return data;
        }

        vm.fixTimestampFormat = function (timestamp) {
            if (timestamp.length < 28) {
                var timestamArr = timestamp.split(' ');
                return timestamArr[0] + "T" + timestamArr[1] + "+0000"
            }
            return timestamp;
        }

        vm.turnToZero = function (index, socialNetwork) {
            switch (socialNetwork) {
                case communicationConstants.SOCIAL_MEDIAS.MESSENGER:
                    vm.allMessengerMessages -= vm.conversationsList[index].unreadMessages || 0;
                    vm.allMessengerMessages = vm.allMessengerMessages < 0 ? 0 : vm.allMessengerMessages;
                    break;
                case communicationConstants.SOCIAL_MEDIAS.INSTAGRAM:

                    vm.allInstaMessages -= vm.conversationsList[index].unreadMessages || 0;
                    vm.allInstaMessages = vm.allInstaMessages < 0 ? 0 : vm.allInstaMessages;
                    break;
                case communicationConstants.SOCIAL_MEDIAS.WATI:
                    vm.allWatiMessages -= vm.conversationsList[index].unreadMessages || 0;
                    vm.allWatiMessages = vm.allWatiMessages < 0 ? 0 : vm.allWatiMessages;
                    break;
            }
            vm.conversationsList[index].unreadMessages = 0;
        }

        vm.getCountdownPercentage = function () {
            var hours = vm.currentConversation.hours * 60;
            hours = hours + vm.currentConversation.minutes;
            vm.currentConversation.percentage = (Math.floor((100 * hours) / 1440)).toFixed(0);
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        vm.updateCountdown = function (should_decrease) {
            if (should_decrease) {
                vm.currentConversation.time = vm.currentConversation.time - oneMinuteInMiliseconds;
            }

            var minutes = Math.floor((vm.currentConversation.time / (1000 * 60)) % 60),
                hours = Math.floor((vm.currentConversation.time / (1000 * 60 * 60)) % 24);


            vm.currentConversation.hours = hours < 0 ? 0 : hours;
            vm.currentConversation.minutes = minutes < 0 ? 0 : minutes;

            vm.currentConversation.currentConversationTime = vm.currentConversation.hours + ':' + vm.currentConversation.minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            vm.getCountdownPercentage();
        }

        function resetTimer() {
            vm.currentConversation.time = vm.startingTime * oneHourInMilliseconds - 1;
            vm.updateCountdown(false)
        }

        function getMetaMessages(conversation) {
            MessengerService.findMessages(conversation.conversationId).then(function (messages) {
                vm.loadingMessages = false;
                vm.showSkeleton = false;

                vm.currentConversation.paging = messages.paging;
                vm.currentConversation.lastIncomingDate = messages.lastIncomingDate;
                vm.openedMessages = messages.data.reverse().map(function (message) {
                    message.recipientId = String(conversation.chatUserId);
                    var data = makeMessageInstaOrMessenger(message, conversation.platform);
                    if (message.story) {
                        vm.getImageFromUrl(data);
                    }
                    return data;
                });

                makeDateInfo();

                var index = vm.conversationsList.findIndex(function (value) {
                    return value.chatUserId == vm.currentConversation.chatUserId;
                })
                if (index > -1) {
                    vm.turnToZero(index, vm.currentConversation.platform);
                }
                setTimeout(function () {
                    document.getElementById('message-input').focus();
                    if ($localStorage.redirectNotificationData) {
                        $localStorage.redirectNotificationData = null;
                    }
                    scrollToBottom();
                }, 500);
                setTimer();
                scrollToBottom();
            }).catch(function (err) {
                vm.loadingMessages = false;
                vm.showSkeleton = false;
            });

            getCustomerByFbOrInstagram(conversation);
        }

        function getWATIMessages() {
            WatiService.findMessagesByNumber({ number: vm.currentConversation.conversationId, pageSize: communicationConstants.PAGE_SIZE.MESSAGES, lastId: vm.lastMessageId }).then(function (result) {
                if (!result.data.ok) {
                    vm.openedMessages = [];
                    toastr.warning($translate.instant('communication.errors.invalidWhatsappNumber'));
                    vm.loadingMessages = false;
                    vm.showSkeleton = false;
                    return;
                }

                var reversedMessages = result.data.result.items ? result.data.result.items.reverse() : [];

                vm.lastMessageId = reversedMessages.length > 0 ? reversedMessages[0].id : null;

                var filtered = reversedMessages.filter(function(message) { 
                    return (!isNullOrUndefined(message.text) && message.text.length > 0) 
                        || message.eventType === "message"
                        || message.eventType === "broadcastMessage"
                        || message.eventType === "sessionMessageSent";
                })

                vm.openedMessages = angular.copy(filtered.filter(function (message) {
                        message.isServer = true;
                        manageMediaMessageFromWATI(message);
                        if (message.replySourceMessage) {
                            message.replySourceMessage.isServer = true;
                            manageMediaMessageFromWATI(message.replySourceMessage, true);
                        }
                        return message;
                    })
                );
                makeDateInfo();
                IndexedDBCommunication.getSendingFileByConversatonId(vm.currentConversation.conversationId, function (sendingFiles) {
                    if($localStorage.failedMessages && $localStorage.failedMessages[storeId]
                        && $localStorage.failedMessages[storeId][vm.currentConversation.chatUserId])
                        vm.failedMessages = $localStorage.failedMessages[storeId][vm.currentConversation.chatUserId];

                    getPendingFilesSending(sendingFiles);

                    vm.loadingMessages = false;
                    vm.showSkeleton = false;
                    setTimeout(function () {
                        document.getElementById('message-input').focus();
                        if ($localStorage.redirectNotificationData) {
                            $localStorage.redirectNotificationData = null;
                        }
                        scrollToBottom();
                    }, 500);
                    setTimer();
                })
            }).catch(function () {
                vm.loadingMessages = false;
                vm.showSkeleton = false;
            });
            getCustomerByWati(vm.currentConversation);
        }

        function getPendingFilesSending(sendingFiles) {
            if (sendingFiles.length > 0) {
                sendingFiles.forEach(function (sendingFile) {
                    var message = _.find(vm.openedMessages, function (message) {
                        return sendingFile.localMessageId === message.localMessageId;
                    });

                    if (message) {
                        if (message.type === 'document' || message.type === 'image' || message.type === 'video') {
                            IndexedDBCommunication.getSendingFileByLocalMessageId(message.localMessageId, function (result) {
                                if (result !== null) {
                                    IndexedDBCommunication.removeSendingFile(result.id, function (){});
                                }
                            });
                        }
                    } else  {
                        var openedMessagesCopy = angular.copy(vm.openedMessages);
                        var previousMessageIndex = _.findIndex(openedMessagesCopy, function (previousMessage) {
                            return previousMessage.id === sendingFile.lastMessageId
                                || previousMessage.localMessageId === sendingFile.lastMessageId;
                        });

                        if (sendingFile.status === 'CANCELED') {
                            sendingFile.message.failedToSend = true;
                            sendingFile.message.loadingDownload = false;
                        }

                        sendingFile.message.localMessageId = sendingFile.localMessageId;

                        vm.openedMessages.splice(previousMessageIndex + 1, 0, sendingFile.message);
                    }
                });

                vm.loadingMessages = false;
                vm.showSkeleton = false;
                scrollToBottom();
            }
        }

        function clearTextMessage() {
            var inputMessage = document.getElementById('message-input');

            if (inputMessage) {
                inputMessage.innerText = ''
            }
            vm.text = '';
        }

        /**
         * Sets value the variable {vm.currentConversation}.
         * @param {*} conversationId
         */
        vm.openConversation = function (conversation) {
            if (vm.loadingMessages || !conversation) {
                return;
            }

            if (vm.recording)
                vm.discardAudio();
            if (vm.selectMultipleMessages)
                vm.closeMessageSelection();
            if (vm.replyMessage)
                vm.closeMessageToReply();

            clearTextMessage();
            vm.failedMessages = [];
            changeMobileFlow('chatConversation');
            vm.currentConversation = conversation;
            vm.pageNumber = 1;
            vm.currentCustomer = {};
            vm.currentAttendant = null;
            vm.loadingMessages = true;
            vm.showSkeleton = true;
            vm.isEditMode = false;
            vm.openedMessages = [];
            switch (vm.currentConversation.platform) {
                case communicationConstants.SOCIAL_MEDIAS.MESSENGER:
                case communicationConstants.SOCIAL_MEDIAS.INSTAGRAM:
                    if (conversation.conversationId) {
                        getMetaMessages(conversation);
                    } else {
                        vm.openedMessages = vm.messenger[conversation.conversationId].messages;
                        vm.failedMessages = $localStorage.failedMessages[storeId][vm.currentConversation.number];
                        makeDateInfo();
                        scrollToBottom();
                    }
                    getCurrentAttendant();

                    break;

                case communicationConstants.SOCIAL_MEDIAS.WATI:
                    vm.lastMessageId = null;
                    var callback = function () {
                        getWATIMessages();
                    }
                    callback();

                    getCurrentAttendant();
                    var index = vm.conversationsList.findIndex(function (value) {
                        return value.conversationId == vm.currentConversation.conversationId;
                    })
                    if (index > -1) {
                        vm.turnToZero(index, vm.currentConversation.platform);
                    }
                    break;
            }

            NotificationService.updateNotificationsAsReadByStoreIdAndConversationId(
                $localStorage.defaultStore.storeId,
                conversation.conversationId
            ).then(function (data) {
                $rootScope.$emit("UpdateNotificationCount", {});
            }, function (err) {
                $localStorage.redirectNotificationData = null;
                vm.loadingConversations = false;
                vm.loadingMessages = false;
            });
        }

        vm.isConversationWithoutMessages = function () {
            var messages = vm.openedMessages.filter(function (message) {
                return message.type !== RESERVED_TYPE;
            })

            return messages.length === 0 && !vm.hasLoadingActive() && vm.openedMessages.length > 0;
        }

        vm.noMessagesLabel = function () {
            return $translate.instant('communication.no-messages-on-conversation', {username: vm.currentConversation.name});
        }

        function setTimer() {
            if (!vm.currentConversation.lastIncomingDate) return;
            var lastTimestamp = parseInt(vm.currentConversation.lastIncomingDate);
            vm.currentConversation.time = vm.startingTime * oneHourInMilliseconds - (moment().valueOf() - lastTimestamp);

            vm.updateCountdown(false);
            if (vm.interval) {
                clearInterval(vm.interval);
            }

            vm.interval = setInterval(function () {
                if (!vm.currentConversation.lastIncomingDate) return;
                vm.updateCountdown(true)
            }, oneMinuteInMiliseconds);
        }

        vm.loadAudioFile = function (message) {
            if (message.isServer && message.fullData) {
                return message.data + '&token=' + WatiService.replaceWatiToken(vm.watiSetting.watiToken);
            } else if (message.isServer) {
                return WatiService.makeUriToShowImageByWati(vm.watiSetting.watiUri, message.data, vm.watiSetting.watiToken);
            }
            return message.data.fullData ? message.data.fullData.fullBase64 : message.data;
        }

        function getProfilePicFromUrl(conversation) {
            var xhr = new XMLHttpRequest();

            if (!conversation.profilePic) {
                conversation.profilePic = "./assets/images/avatar.png";
                return;
            }

            if (conversation.profilePic == "./assets/images/avatar.png") {
                return;
            }

            xhr.onreadystatechange = function (oEvent) {
                if (xhr.readyState === 4) {
                    if (xhr.status !== 200) {
                        conversation.profilePic = "./assets/images/avatar.png";
                        $scope.$apply();
                    }
                }
            }

            xhr.open('GET', conversation.profilePic);
            xhr.responseType = 'blob';

            try {
                xhr.send()
            } catch (error) {
                console.log(error)
            }
        }

        vm.openFullScreenPreview = function (message) {
            if (!message.failedToSend) {
                vm.messageFullScreen = {
                    isFullScreen:true,
                    message: message
                }
            }
        };

        vm.closeFullScreenPreview = function () {
            vm.messageFullScreen = {
                isFullScreen:false,
                message: null
            }
        };

        vm.getImageFromUrl = function (message) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function (result) {
                    var foundMessage = vm.openedMessages.find(function (messageOnList) {
                        return messageOnList.id === message.id;
                    });
                    foundMessage.storyType = 'video';
                    foundMessage.loadComplete = true;
                    $scope.$apply();
                }
                reader.readAsDataURL(xhr.response);
            };

            xhr.onreadystatechange = function (oEvent) {
                if (xhr.readyState === 4) {
                    if (xhr.status !== 200) {
                        var foundMessage = vm.openedMessages.find(function (messageOnList) {
                            return messageOnList.id === message.id;
                        });
                        foundMessage.storyType = 'image';
                        foundMessage.loadComplete = true;
                        $scope.$apply();
                    }
                }
            }

            xhr.open('GET', message.data);
            xhr.responseType = 'blob';

            try {
                xhr.send()
            } catch (error) {
                console.log(error)
            }
        }

        vm.getUrlImage = function (message) {
            if (message.isServer && message.data && message.data.includes(vm.watiSetting.watiUri)) {
                return message.data;
            }
            if (message.isServer && message.fullData) {
                return message.fullData + '&token=' + WatiService.replaceWatiToken(vm.watiSetting.watiToken);
            } else if (message.isServer) {
                return WatiService.makeUriToShowImageByWati(vm.watiSetting.watiUri, message.data, vm.watiSetting.watiToken);
            }
            return message.data.fullData ? message.data.fullData.fullBase64 : message.data;
        }

        vm.downloadDocument = function (message) {
            if (message.downloadedData) {
                $window.open(message.downloadedData);
            } else {
                message.loadingDownload = true;
                WatiService.findFileByName({ fileName: message.data }).then(function (result) {
                    var blob = new Blob([result.data], { type: result.data.type });
                    var url = $window.URL.createObjectURL(blob);
                    message.downloadedData = url;
                    message.loadingDownload = false;
                });
            }
        }

        vm.downloadMediaFromWATI = function (data) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", data, true);
            xhr.responseType = "blob";
            xhr.onload = function(){
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(this.response);
                var tag = document.createElement('a');
                tag.href = imageUrl;
                tag.download = 'Showkase Image ' + moment().format();
                document.body.appendChild(tag);
                tag.click();
                document.body.removeChild(tag);
            }
            xhr.send();
        }

        /**
         * Gets called when conversation's messages are scrolled to top and updates the shown messages to include older ones.
         */
        vm.messagesScrolledToTop = function () {
            if (vm.loadingMessages || (vm.currentConversation.platform !== 'WATI' && !vm.currentConversation.paging)) {
                return;
            }

            vm.loadingMoreMessages = true;
            if (vm.currentConversation.platform !== communicationConstants.SOCIAL_MEDIAS.WATI) {
                MessengerService.findMessages(vm.currentConversation.conversationId, vm.currentConversation.paging ? vm.currentConversation.paging.cursors.after : null).then(function (messages) {
                    vm.currentConversation.paging = messages.paging;
                    vm.openedMessages = messages.data.reverse().map(function (message) {
                        message.recipientId = String(vm.currentConversation.chatUserId);
                        return makeMessageInstaOrMessenger(message, vm.currentConversation.platform);
                    }).concat(vm.openedMessages);

                    vm.loadingMoreMessages = false;

                    if($localStorage.failedMessages && $localStorage.failedMessages[storeId]
                        && $localStorage.failedMessages[storeId][vm.currentConversation.chatUserId])
                        vm.failedMessages = $localStorage.failedMessages[storeId][vm.currentConversation.chatUserId];
                    makeDateInfo();
                    $timeout(function () {
                        var scroller = document.getElementById("messages-container");
                        if (scroller) {
                            scroller.scrollTop = 200;
                        }
                    }, 0, false);
                });
                return;
            }
            vm.pageNumber = vm.pageNumber + 1;
            WatiService.findMessagesByNumber({ number: vm.currentConversation.conversationId, pageSize: communicationConstants.PAGE_SIZE.MESSAGES, lastId: vm.lastMessageId }).then(function (result) {

                var olderMessages = angular.copy(result.data.result.items ? result.data.result.items.reverse().filter(function (message) {
                    message.isServer = true;

                    if (message.replySourceMessage) {
                        message.replySourceMessage.isServer = true;
                        manageMediaMessageFromWATI(message.replySourceMessage, true);
                    }

                    manageMediaMessageFromWATI(message);
                    return message;
                }) : []);

                vm.openedMessages = olderMessages.concat(vm.openedMessages);

                vm.openedMessages = vm.openedMessages.filter(function(message) { 
                    return (!isNullOrUndefined(message.text) && message.text.length > 0) 
                        || message.eventType === "message"
                        || message.eventType === "broadcastMessage"
                        || message.eventType === "sessionMessageSent";
                });

                vm.openedMessages.forEach(function(message) {
                    manageMediaMessageFromWATI(message);
                });

                vm.loadingMoreMessages = false;

                if($localStorage.failedMessages && $localStorage.failedMessages[storeId]
                    && $localStorage.failedMessages[storeId][vm.currentConversation.chatUserId]) {
                        vm.failedMessages = $localStorage.failedMessages[storeId][vm.currentConversation.chatUserId];
                }

                if (olderMessages && olderMessages[0]) {
                    vm.lastMessageId = olderMessages[0].id;
                } else {
                    return;
                }

                makeDateInfo();

                $timeout(function () {
                    var scroller = document.getElementById("messages-container");
                    if (scroller) {
                        scroller.scrollTop = 200;
                    }
                }, 0, false);
            })
        }

        function getFileNameFromUrl(value) {
            if (!value) {
                return "";
            }

            var fileName = "";

            if (value.includes("fileName=") && value.includes(".opus")) {
                var aux = value.split('fileName=')[1];
                fileName = aux.split('.opus')[0] + '.opus';
            } else if (value.includes("fileName=") && value.includes(".mp3")) {
                var aux = value.split('fileName=')[1];
                fileName = aux.split('.mp3')[0] + '.mp3';
            } else if (value.includes("fileName=") && value.includes("?")) {
                var aux = value.split('fileName=')[1];
                fileName = aux.split('?')[0];
            } else if (value.includes("fileName=")) {
                fileName = value.split('fileName=')[1];
            } else {
                fileName = value;
            }

            return fileName;
        }

        function manageMediaMessageFromWATI(message, isReply) {
            switch (message.type) {
                case communicationConstants.TYPES.IMAGE.toLowerCase():
                    message.data = vm.getUrlImage(message);
                    break;
                case communicationConstants.TYPES.STICKER.toLowerCase():
                    if (message.data.includes('/data')) {
                        message.data = message.data.replace('/data', 'data');
                    }
                    message.api = vm.stickersApi.replace("data_default/stickers/{name}", message.data);
                    break;
                case communicationConstants.TYPES.AUDIO.toLowerCase():
                    var fileName = getFileNameFromUrl(message.data);
                    if (message.isServer) {
                        message.loadingDownload = true;
                    }
                    WatiService.findAudioFileByName({ fileName: fileName }).then(function (result) {
                        if (result && result.data) {
                            var foundMessage = _.find(vm.openedMessages, function (openedMessage) {
                                return openedMessage.id === message.id;
                            });

                            if (foundMessage) {
                                message = foundMessage;
                            }

                            var blob = new Blob([result.data], {
                                type: 'audio/mp3'
                            });

                            var url = URL.createObjectURL(blob);

                            if (isReply) {
                                document.getElementById('reply-' + message.id).src = url;
                            } else {
                                document.getElementById(message.id).src = url;
                            }

                            message.loadingDownload = false;
                        }
                    });

                    break;
                case communicationConstants.TYPES.VIDEO.toLowerCase():
                    if (message.data.includes('/data')) {
                        message.data = message.data.replace('/data', 'data');
                    }

                    if (message.data && message.data.includes(vm.watiSetting.watiUri)) {
                        break;
                    }

                    message.data = WatiService.makeUriToShowVideoByWati(vm.watiSetting.watiUri, message.data, vm.watiSetting.watiToken);
                    break;
                default:
                    break;
            }

            if (message.eventType == 'broadcastMessage') {
                message.type = 'broadcastMessage';
                message.text = message.finalText;
                if (message.template && message.template.header) {
                    if (message.template.header.type === 2) {
                        message.data = message.template.header.link !== '' ? message.template.header.link
                            : WatiService.makeUriToShowImageByWati(vm.watiSetting.watiUri,
                                'data/images/' + message.template.header.mediaFromPC, vm.watiSetting.watiToken);
                    } else if (message.template.header.type === 3) {
                        message.data = message.template.header.link !== '' ? message.template.header.link
                            : WatiService.makeUriToShowImageByWati(vm.watiSetting.watiUri,
                                'data/videos/' + message.template.header.mediaFromPC, vm.watiSetting.watiToken);
                    } else if (message.template.header.type === 4) {
                        message.data = message.template.header.link !== '' ? message.template.header.link
                            : 'data/documents/' + message.template.header.mediaFromPC;
                    }
                }
                message.owner = true;
            }

            if (message.statusString === 'FAILED') {
                message.failedToSend = true;
            }
        }

        function makeDateInfo() {
            vm.openedMessages = vm.openedMessages.filter(function (item) {
                return item.type != RESERVED_TYPE
            });
            vm.openedMessagesAux = angular.copy(vm.openedMessages);
            var changedOne = false;

            if (vm.openedMessagesAux.length === 0) {
                vm.openedMessages.push({ type: RESERVED_TYPE, value: getDayLabel(moment().valueOf()) });
                return;
            }

            var dateToConsider = vm.openedMessagesAux[0].created
                ? parseInt(vm.openedMessagesAux[0].created)
                : parseInt(vm.openedMessagesAux[0].timestamp);
            var dateToConsiderFormatted = moment(dateToConsider).format(FORMAT_DATE);

            var listResult = [];
            vm.openedMessagesAux.forEach(function (message, index) {
                var currentMessageDate = moment(message.created ? parseInt(message.created) : parseInt(message.timestamp)).format(FORMAT_DATE);
                if (dateToConsiderFormatted !== currentMessageDate) {
                    dateToConsider = message.created ? message.created : message.timestamp;
                    listResult.push({ type: RESERVED_TYPE, value: getDayLabel(dateToConsider) });
                    dateToConsiderFormatted = currentMessageDate;
                    changedOne = true;
                }
                listResult.push(message);

            });

            if ((vm.openedMessagesAux[0].timestamp || vm.openedMessagesAux[0].created)) {
                listResult.unshift({ type: RESERVED_TYPE, value: getDayLabel(vm.openedMessagesAux[0].created
                        ? vm.openedMessagesAux[0].created
                        : vm.openedMessagesAux[0].timestamp) });
            }

            vm.openedMessages = angular.copy(listResult);
        }

        vm.getMessageDayLabel = function (date) {
            return getDayLabel(date, true)
        }

        vm.zoomImage = function (e) {
            var image = $(e.currentTarget);

            if (image.hasClass('zoomed')) {
                image.removeClass('zoomed');
                image[0].style.cursor = 'zoom-in';
            } else {
                image.addClass('zoomed');
                image[0].style.cursor = 'zoom-out';
            }
        }

        vm.moveZoomedImage = function (e){
            var image = $(e.currentTarget);

            var percentX = ((e.pageX - image[0].offsetLeft) / image[0].offsetWidth) * 100;
            var percentY = ((e.pageY - image[0].offsetTop) / image[0].offsetHeight) * 100;

            image.css({
                'transform-origin': percentX + '%' + ' ' + percentY + '%',
            });
        }

        function getDayLabel(date, isFromConversationList) {
            date = parseInt(date);
            var today = moment();
            var messageDate = moment(date);

            if (today.diff(messageDate, 'days') < 7) {
                if (moment(messageDate).isSame(moment(), "day")) {
                    return isFromConversationList
                        ? moment(date).format(FORMAT_TIME)
                        : $translate.instant('communication.days.today');
                } else if (messageDate.isSame(today.subtract(1, 'days').startOf('day'), 'day')){
                    return $translate.instant('communication.days.yesterday');
                }
                return messageDate.format('dddd');
            }

            return moment(date).format(FORMAT_DATE);
        }

        /**
         * Gets called when sticker picker is scrolled to bottom
         */
        vm.stickersScrolledToBottom = function () {
            vm.stickersPageNumber = vm.stickersPageNumber + 1;
            WatiService.findStickers({ pageNumber: vm.stickersPageNumber, pageSize: 9 }).then(function (result) {
                result.data.items.map(function (sticker) {
                    sticker.api = vm.stickersApi.replace("data_default/stickers/{name}", sticker.content.slice(1))
                    vm.stickers.push(sticker);
                })
            });
        }

        /**
         * Config the chat of Instagram and Messenger.
         */
        function configSubscriberInstaMessenger(chatConversation, message) {
            var index = vm.conversationsList.findIndex(function (value) {
                return value.conversationId == chatConversation.conversationId;
            })
            var data = { text: chatConversation.lastMessage, type: communicationConstants.MESSAGES_TYPES.TEXT, owner: false };

            if (!vm.conversations.get(chatConversation.conversationId)) {
                vm.messenger[chatConversation.conversationId] = { messages: [data] };

                if (verifySelectedFilters(vm.filters)) {
                    vm.allMessengerMessages += 1;
                }
                vm.conversationsList.unshift(chatConversation);
                vm.conversations.set(String(chatConversation.conversationId), chatConversation);

                makeDateInfo();
                receiveMessage(chatConversation);

            } else if (String(vm.currentConversation.conversationId) === String(chatConversation.conversationId)) {
                resetTimer();
                vm.turnToZero(index, chatConversation.platform);
                var messageData = {
                    isSource: false,
                    text: chatConversation.lastMessage,
                    type: chatConversation.lastMessage ? communicationConstants.TYPES.TEXT.toLowerCase() : message.messaging[0].message.attachments[0].type,
                    created: chatConversation.lastMessageDate,
                    isServer: true,
                    socialMediaName: chatConversation.platform
                };

                if (vm.attachmentTypes.includes(messageData.type)) {
                    messageData.data = message.messaging[0].message.attachments[0].payload.url;
                } else if (messageData.type !== communicationConstants.TYPES.TEXT.toLowerCase()) {
                    messageData.text = $translate.instant('communication.errors.unsupportedMessage');
                    messageData.type = communicationConstants.MESSAGES_TYPES.UNSUPPORTED;
                }

                updateMetaConversationData(chatConversation, vm.conversationsList[index]);
                vm.conversationsList[index].unreadMessages = 0;
                chatConversation.unreadMessages = 0
                vm.messenger[chatConversation.conversationId].messages.push(messageData);
                vm.openedMessages.push(messageData);
                receiveMessage(vm.currentConversation)
                makeDateInfo();
                scrollToBottom();
            } else {
                var messageData = {
                    isSource: false,
                    text: chatConversation.lastMessage,
                    type: communicationConstants.MESSAGES_TYPES.TEXT,
                    created: chatConversation.lastMessageDate,
                    fullData: message,
                };
                if (!vm.conversations.get(String(chatConversation.conversationId)).conversationId) {
                    vm.messenger[chatConversation.conversationId].messages.push(messageData);
                }

                receiveMessage(vm.conversationsList[index]);

                vm.conversationsList = array_move(vm.conversationsList, index, 0);
                updateMetaConversationData(chatConversation, vm.conversationsList[0]);
                if (verifySelectedFilters(vm.filters)) {
                    vm.allMessengerMessages += 1;
                }
            }

            if (vm.conversations.get(String(chatConversation.conversationId))) {
                vm.conversations.get(String(chatConversation.conversationId)).lastMessage = chatConversation.lastMessage;
            }

            if (String(vm.currentConversation.conversationId) !== String(chatConversation.conversationId) ) {
                if (chatConversation.platform === communicationConstants.SOCIAL_MEDIAS.MESSENGER) {
                    if (!verifySelectedFilters(vm.filters)) {
                        updateUnreadMessagesBySocialMedia(communicationConstants.SOCIAL_MEDIAS.MESSENGER);
                    } else {
                        vm.allMessengerMessages += chatConversation.unreadMessages;
                    }
                } else {
                    if (!verifySelectedFilters(vm.filters)) {
                        updateUnreadMessagesBySocialMedia(communicationConstants.SOCIAL_MEDIAS.INSTAGRAM);
                    } else {
                        vm.allInstaMessages += chatConversation.unreadMessages;
                    }
                }

            }
            $scope.$apply()
        }

        function updateMetaConversationData(conversationSource, conversationEnd) {
            conversationEnd.text = conversationSource.lastMessage;
            conversationEnd.lastMessage = conversationSource.lastMessage;
            conversationEnd.unreadMessages = conversationSource.unreadMessages;
            conversationEnd.lastMessageDate = moment(conversationSource.lastMessageDate).valueOf();
            conversationEnd.lastIncomingDate = moment(conversationSource.lastIncomingDate).valueOf();
            conversationEnd.status = conversationSource.status;
        }

        /**
         * Subscribe on socket to receive notification of the Messenger.
         */
        function subscribeOnMessenger(message) {
            if (vm.currentSocialMedia !== 'ALL' && vm.currentSocialMedia !== communicationConstants.SOCIAL_MEDIAS.MESSENGER) {
                return;
            }

            var chatConversation = {
                conversationId: message.userSocialMediaId,
                chatUserId: message.messaging[0].sender.id,
                name: message.messaging[0].sender.name,
                profilePic: message.messaging[0].sender.profilePic,
                lastMessage: message.messaging[0].message.text || '',
                messageType: 'text',
                chatTicketId: message.chatTicketId,
                unreadMessages: message.chatUnreadMessage.unreadMessages,
                platform: communicationConstants.SOCIAL_MEDIAS.MESSENGER,
                lastIncomingDate: message.time,
                lastMessageDate: message.time,
                status: communicationConstants.CHAT_STATUS.NEW
            }

            configSubscriberInstaMessenger(chatConversation, message)
        }

        /**
         * Subscribe on socket to receive notification of the Instagram.
         */
        function subscribeOnInstagram(message) {
            if (vm.currentSocialMedia !== 'ALL' && vm.currentSocialMedia !== communicationConstants.SOCIAL_MEDIAS.INSTAGRAM) {
                return;
            }
            var chatConversation = {
                conversationId: message.userSocialMediaId,
                chatUserId: message.messaging[0].sender.id,
                name: message.messaging[0].sender.name,
                profilePic: message.messaging[0].sender.profilePic,
                lastMessage: message.messaging[0].message.text || '',
                messageType: 'text',
                chatTicketId: message.chatTicketId,
                unreadMessages: message.chatUnreadMessage.unreadMessages,
                platform: communicationConstants.SOCIAL_MEDIAS.INSTAGRAM,
                lastIncomingDate: message.time,
                lastMessageDate: message.time,
                status: communicationConstants.CHAT_STATUS.NEW
            }

            configSubscriberInstaMessenger(chatConversation, message);
        }

        function updateUnreadMessagesBySocialMedia(socialMedia) {
            ChatTicketService.getSumBySocialMediaName(socialMedia).then(function (result) {
                switch (socialMedia) {
                    case communicationConstants.SOCIAL_MEDIAS.WATI:
                        vm.allWatiMessages = parseInt(result.sum) || 0;
                        break;
                    case communicationConstants.SOCIAL_MEDIAS.MESSENGER:
                        vm.allMessengerMessages = parseInt(result.sum) || 0;
                        break
                    case communicationConstants.SOCIAL_MEDIAS.INSTAGRAM:
                        vm.allInstaMessages = parseInt(result.sum) || 0;
                        break;
                }

            });
        }


        /**
         * Subscribe on socket to receive notification of the Wati.
         */
        function subscribeOnWhatsapp(message) {
            if (vm.currentSocialMedia !== 'ALL' && vm.currentSocialMedia !== communicationConstants.SOCIAL_MEDIAS.WATI) {
                return;
            }
            var index = vm.conversationsList.findIndex(function (value) {
                return value.conversationId == message.conversationId;
            });

            if (!vm.conversations.has(message.conversationId)) {
                var chatConversation = {
                    conversationId: message.conversationId,
                    chatUserId: message.waId,
                    name: message.senderName ? message.senderName : message.waId,
                    platform: communicationConstants.SOCIAL_MEDIAS.WATI,
                    unreadMessages: message.owner || message.type === 'template' ? 0 : 1,
                    chatTicketId: message.chatTicketId,
                    lastMessage: message.listReply ? message.listReply.title : message.text,
                    messageType: 'text',
                    lastIncomingDate: message.owner || message.type === 'template' ? null : message.timestamp,
                    lastMessageDate: message.timestamp,
                    isFavorite: false,
                    status: message.type !== 'template' ? communicationConstants.CHAT_STATUS.NEW : vm.conversationsList[0].status
                };
                vm.conversationsList.unshift(chatConversation);
                vm.conversations.set(message.conversationId, chatConversation);
                if (!verifySelectedFilters(vm.filters)) {
                    updateUnreadMessagesBySocialMedia(communicationConstants.SOCIAL_MEDIAS.WATI);
                } else {
                    vm.allWatiMessages = vm.allWatiMessages + 1;
                }
            } else {
                if (!(message.owner || message.type === 'template')) {
                    vm.conversationsList[index].unreadMessages = parseInt(vm.conversationsList[index].unreadMessages) + 1;
                }
                if (vm.currentConversation.conversationId !== message.conversationId) {
                    if (!verifySelectedFilters(vm.filters)) {
                        updateUnreadMessagesBySocialMedia(communicationConstants.SOCIAL_MEDIAS.WATI);
                    } else if (!(message.owner || message.type === 'template')) {
                        vm.allWatiMessages = vm.allWatiMessages + 1;
                    }
                } else {
                    if (!(message.owner || message.type === 'template') && message.operatorName && !message.operatorName.toUpperCase().includes('BOT')) resetTimer();
                    vm.conversationsList[0].status = communicationConstants.CHAT_STATUS.OPENED;
                    vm.conversationsList[index].unreadMessages = 0;
                    vm.turnToZero(index, communicationConstants.SOCIAL_MEDIAS.WATI);
                }
                vm.conversationsList = array_move(vm.conversationsList, index, 0);
                vm.conversationsList[0].text = message.text;
                vm.conversationsList[0].lastMessage = message.listReply ? message.listReply.title : message.text;
                vm.conversationsList[0].conversationId = message.conversationId;
                vm.conversationsList[0].lastIncomingDate = message.owner  || message.type === 'template' ? vm.conversationsList[0].lastIncomingDate : message.timestamp;
                vm.conversationsList[0].lastMessageDate = message.timestamp;
                vm.conversationsList[0].status = message.type !== 'template' ? communicationConstants.CHAT_STATUS.NEW : vm.conversationsList[0].status;
                vm.conversationsList[0].messageType = message.type === 'interactive' ? 'text' : message.type;
            }

            var time = timeConverter(message.timestamp);

            if (vm.currentConversation.conversationId == message.conversationId && message) {
                var receivedMessage = {
                    id: message.id,
                    isSource: false,
                    text: message.text ? message.text : '',
                    type: message.type === 'interactive' ? 'text' : message.type,
                    created: time,
                    orderProducts: message.orderProducts,
                    fullData: message.data,
                    isServer: true,
                    owner: message.owner,
                    contacts: message.messageContact
                };
                if (message.listReply) {
                    receivedMessage.text =  message.listReply.title;
                }

                switch (message.type) {
                    case communicationConstants.TYPES.DOCUMENT.toLowerCase():
                        receivedMessage.data = message.data.split('fileName=')[1];
                        IndexedDBCommunication.getSendingFileByFilenameAndConversationId(message.text, vm.currentConversation.conversationId,
                            function (result) {
                                if (result !== null) {
                                    _.remove(vm.openedMessages, function (messageFrom) {
                                        return messageFrom.loadingDownload && (result.localMessageId === messageFrom.localMessageId);
                                    });
                                    IndexedDBCommunication.removeSendingFile(result.id, function () {
                                        vm.openedMessages.push(receivedMessage);
                                    });
                                }
                        });
                        break;
                    case communicationConstants.TYPES.IMAGE.toLowerCase():
                        receivedMessage.data = vm.getUrlImage(receivedMessage);
                        break;
                    case communicationConstants.TYPES.STICKER.toLowerCase():
                        receivedMessage.api = message.data + '&token=' + WatiService.replaceWatiToken(vm.watiSetting.watiToken);
                        break
                    case communicationConstants.TYPES.AUDIO.toLowerCase():
                        var fileName = getFileNameFromUrl(message.data);
                        WatiService.findAudioFileByName({ fileName: fileName }).then(function (result) {
                            if (result && result.data) {

                                var blob = new Blob([result.data], {
                                    type: 'audio/mp3'
                                });

                                var url = URL.createObjectURL(blob);

                                document.getElementById(message.id).src = url;

                            }
                        });
                        break
                    default:
                        break;
                }

                if (!receivedMessage.owner || message.operatorName && message.operatorName.toUpperCase().includes('BOT')) {
                    vm.openedMessages.push(receivedMessage);
                }
                scrollToBottom();
            }

            if (vm.openChatAfterForwardMessages) {
                vm.openChatAfterForwardMessages = false;
                vm.openConversation(vm.conversationsList[0]);
            }

            vm.conversations.get(message.conversationId).text = message.listReply ? message.listReply.title : message.text;

            receiveMessage(vm.conversationsList[0]);

            $scope.$apply();
        }

        $scope.$watch('vm.allWatiMessages', function (newVal, oldVal) {
            vm.allMessages += (newVal - oldVal);
            $rootScope.$emit('$countMessages', vm.allMessages);
        });

        $scope.$watch('vm.allInstaMessages', function (newVal, oldVal) {
            vm.allMessages += (newVal - oldVal);
            $rootScope.$emit('$countMessages', vm.allMessages);
        });

        $scope.$watch('vm.allMessengerMessages', function (newVal, oldVal) {
            vm.allMessages += (newVal - oldVal);
            $rootScope.$emit('$countMessages', vm.allMessages);
        });

        function removeNine(numberWithNine) {
            return numberWithNine.slice(1, 5) + numberWithNine.slice(6, numberWithNine.length)
        }

        /**
         * Opens the modal to start new conversation.
         */
        vm.openNewConversationModal = function () {
            vm.recommendationModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/new-conversation-modal/new-conversation-modal.html',
                controller: 'NewConversationController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    watiSetting: function () {
                        return vm.watiSetting;
                    }
                },
                keyboard: false
            }).result.then(function (response) {
                if (response) {
                    var id = String(response.user);
                    if (response.type === 'wati') {
                        if (vm.currentConversation.chatUserId === id.slice(1, id.length) || removeNine(id) === vm.currentConversation.chatUserId) {
                            var messageData = {
                                text: response.message.bodyOriginal,
                                owner: true,
                                type: 'text',
                                created: moment().valueOf()
                            };
                            vm.openedMessages.push(messageData);
                        }
                    } else {
                        if (String(vm.currentConversation.chatUserId) === String(id)) {
                            var messageData = {
                                text: response.message,
                                owner: true,
                                type: 'text',
                                created: moment().valueOf()
                            };
                            vm.openedMessages.push(messageData);
                        }
                    }
                }
            });
        }

        vm.openBagChatModal = function () {
            vm.bagChatModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/bag-chat-modal/bag-chat.html',
                controller: 'BagChatController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                resolve: {
                    currentCustomer: function () {
                        return vm.currentCustomer
                    },
                    currentSellerId: function () {
                        return $localStorage.userId
                    }
                },
                keyboard: false
            }).result.then(function (response) {
                if (response) {
                    vm.text = communicationConstants.TEMPLATE_MESSAGE_RECOMMENDATION.replace('{url}', response.url);
                    vm.updateMessageText()
                    toastr.success('Mala pronta para envio');
                }
            });
        };

        vm.openLocationChatModal = function () {
            vm.bagChatModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/location-chat-modal/location-chat.html',
                controller: 'LocationChatController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                keyboard: false
            }).result.then(function (response) {
                if (response) {
                    vm.text = response;
                    vm.isSendLocation = true;
                    vm.sendTextMessage();
                }
            });
        };

        vm.openSingleProductsModal = function () {
            vm.singleProductsModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/single-products/single-products.html',
                controller: 'SingleProductController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                resolve: {
                    conversation: function () {
                        return vm.currentConversation
                    },
                    currentCustomer: function () {
                        return vm.currentCustomer
                    }
                },
                keyboard: false
            }).result.then(function (response) {
                if (response) {
                    var index = vm.conversationsList.findIndex(function (value) {
                        return value.conversationId === vm.currentConversation.conversationId;
                    });
                    var lastMessage = '';
                    if (response.isCarousel) {
                        response.flyers = response.flyers.slice(0, 15);
                        var messageData = {
                            flyers: response.flyers,
                            owner: true,
                            type: communicationConstants.MESSAGES_TYPES.CAROUSEL,
                            created: moment().valueOf()
                        };
                        var title = '';
                        response.flyers.forEach(function (flyer) {
                            var price = flyer.finalValue ? flyer.finalValue : flyer.purchaseValue
                            title = flyer.name + ' - R$ ' + price.toLocaleString('pt-br', { minimumFractionDigits: 2 });
                            flyer.name = title
                        });
                        lastMessage = title;
                        vm.openedMessages.push(messageData);
                        MessengerService.sendCarousel(response.flyers, vm.currentConversation.chatUserId).then(function (result) {
                            toastr.success('Produtos enviados em modo de carousel');
                        });
                    } else {
                        response.watiMessages.forEach(function (message) {
                            vm.openedMessages.push(message);
                        });
                        lastMessage = response.watiMessages[response.watiMessages.length - 1];
                        scrollToBottom();
                    }

                    vm.conversationsList = array_move(vm.conversationsList, index, 0);
                    vm.conversationsList[0].text = lastMessage;
                    vm.conversationsList[0].lastUpdated = moment().valueOf();
                }
            });
        };

        vm.addEmojiToTextBox = function (emoji) {
            vm.text = vm.text ? vm.text + emoji : emoji;
            vm.updateMessageText()
        }

        vm.setEmojiFilter = function (filter) {
            vm.currentEmojiFilter = filter;
            setFilteredEmojis();
        }

        function setFilteredEmojis(value) {
            if (value) {
                vm.filteredEmojis = {}
                for (var i = 0; i < vm.emojis.length - 1; i++) {
                    if (vm.emojis[i] != undefined && vm.emojis[i].translatedName.includes(value)) {
                        vm.filteredEmojis[vm.emojis[i].code] = vm.emojis[i]
                    }
                }
            } else {
                vm.filteredEmojis = {}
                Object.entries(vm.emojis).map(function (emoji) {
                    if (vm.currentEmojiFilter == "all") {
                        vm.filteredEmojis[emoji[0]] = emoji[1];
                    }
                    if (emoji[1].category == vm.currentEmojiFilter) {
                        vm.filteredEmojis[emoji[0]] = emoji[1];
                    }
                })
            }
        }

        /*
            searchs emojis by typed word
        */
        vm.searchEmoji = function (word) {
            setFilteredEmojis(word)
        }

        vm.sendStickerMessage = function (sticker) {
            sendStickerToWhatsapp(vm.currentConversation.chatUserId,
                { data: sticker.content, mimeType: sticker.media.mimeType, serverFile: true },
                communicationConstants.TYPES.STICKER.toLowerCase(), sticker.content, sticker.api);
        }

        /**
         * deletes a not or a tag
         * @param {the tag id} tagId
         * @param {is true if it's suppose to delete a tag} isTag
         */
        vm.deleteNoteTag = function (tagId, isTag) {
            NoteTagService.deleteNoteTag(tagId).then(function () {
                isTag ?
                    vm.tags = vm.tags.filter(function (value) {
                        return value.id != tagId && value.type == "tag";
                    }) :
                    vm.notes = vm.notes.filter(function (value) {
                        return value.id != tagId && value.type == "note";
                    })
            })
        }

        /**
         * adds a new tag or note to the user
         * @param {the text of the tag that's being created} newNoteTagText
         * @param {is true if it's suppose to add a tag} isTag
         */
        vm.addNoteTag = function (newNoteTagText, isTag) {
            if (newNoteTagText == "" || newNoteTagText == null) {
                toastr.warning('O texto não pode ser vazio');
                return;
            }

            if (vm.isEditingNote == true) {
                NoteTagService.updateNote({ noteId: vm.currentlyEditingNote, text: newNoteTagText }).then(function (result) {
                    for (var i = 0; i < vm.notes.length; i++) {
                        if (vm.notes[i].id == vm.currentlyEditingNote) {
                            vm.notes[i].text = result.text;
                            break;
                        }
                    }
                })
            } else {
                NoteTagService.createNoteTag({
                    "customerId": vm.currentCustomer.id,
                    "text": newNoteTagText,
                    "type": isTag ? "tag" : "note",
                    "createdBy": $localStorage.userId
                }).then(function (result) {
                    result.timestamp = convertToDatePT(result.timestamp.substring(0, 10)) + " " + result.timestamp.substring(11, 16);
                    isTag ? vm.tags.push(result) : vm.notes.push(result);
                });
            }
            isTag ? vm.newTagText = "" : vm.newNoteText = "";

            vm.isWritingNote = false;
            vm.isEditingNote = false;
        }

        vm.startWritingNote = function (isEditingNote, noteId, index) {
            if (isEditingNote) {
                vm.newNoteText = vm.notes[index].text;
            }

            vm.isWritingNote = true;
            vm.isEditingNote = isEditingNote;
            vm.currentlyEditingNote = noteId;
        }

        vm.stopWritingNote = function () {
            vm.newNoteText = "";
            vm.isWritingNote = false;
            vm.isEditingNote = false;
        }

        function loadNotesTags(customerId) {
            NoteTagService.getNotesTags(customerId).then(function (notesTags) {
                vm.notes = notesTags.filter(function (noteTag) {
                    noteTag.timestamp = convertToDatePT(noteTag.timestamp.substring(0, 10)) + " " + noteTag.timestamp.substring(11, 16);
                    if (noteTag.id && noteTag.type == 'note') {
                        return true
                    };
                })

                vm.tags = notesTags.filter(function (noteTag) {
                    if (noteTag.id && noteTag.type == 'tag') {
                        return true
                    };
                });

                vm.loadingCustomerInfos = false;
            }, function (err) {
                vm.loadingCustomerInfos = false;
            });
        }

        //2021-10-01T15:51:08.760+0000
        function convertToDatePT(date) {
            var newDate = date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
            return newDate;
        }

        function splitBase64FileIntoChunks(base64String, chunkSize) {
            var totalLength = base64String.length;
            var chunks = [];

            for (var i = 0; i < totalLength; i += chunkSize) {
                var chunk = base64String.slice(i, i + chunkSize);
                chunks.push(chunk);
            }

            return chunks;
        }

        vm.retrySendMediaMessageToWhatsapp = function (message) {
            IndexedDBCommunication.getSendingFileByLocalMessageId(message.localMessageId, function (result) {
                if (result !== null) {

                    _.remove(vm.openedMessages, function (openedMessage) {
                        return openedMessage.localMessageId === result.localMessageId;
                    });

                    IndexedDBCommunication.removeSendingFile(result.id, function () {
                        sendMediaMessageToWhatsapp(vm.currentConversation.chatUserId,
                            message.data, message.type, message.text);
                    });
                }
            });
        }

        /* Sends files, audio, images and stickers to whatsapp*/
        function sendAudioMessageToWhatsapp(number, media, type, text, stickerApi, message) {
            vm.sendMessageBtnLoading = false;
            if(media.fullData && media.fullData.blob) {
                var blob = media.fullData.blob
                delete media.fullData.blob
            }

            var failedMessages = $localStorage.failedMessages ? $localStorage.failedMessages : {};
            var storeMessages = failedMessages[storeId];

            if (!message) {
                var message = {
                    owner: true,
                    text: text,
                    type: type,
                    data: media,
                    isServer: false,
                    created: new Date().getTime(),
                    api: stickerApi,
                    downloadedData: media.blob,
                    loadingDownload: true,
                    loading: true
                };
                if (type == communicationConstants.TYPES.IMAGE.toLowerCase() ||
                    type == communicationConstants.TYPES.VIDEO.toLowerCase()) {
                    message.data = vm.getUrlImage(message);
                } else if (type == communicationConstants.TYPES.AUDIO.toLowerCase()) {
                    message.data = blob;
                }
                vm.openedMessages.push(message);
                clearTextMessage();
                scrollToBottom();
            }

            WatiService.sendMessage({ number: number, text: text, media: media, type: type }).then(function () {
                if (vm.currentConversation.attendantId !== $localStorage.userId) {
                    assignChatTicketToMeIfNeeded();
                }

                if (storeMessages != null && storeMessages[number] != null) {
                    failedMessages[storeId][number] = storeMessages[number].filter(function (m) {
                        return m.media != media;
                    });
                    $localStorage.failedMessages = failedMessages;
                    vm.failedMessages = failedMessages[storeId][number];
                }

                message.loading = false;
                message.loadingDownload = false;
                scrollToBottom();
                updateConversationData(number, $localStorage.userId, text);
                vm.attemptToSendMedia = 0;
            }).catch(function (error) {
                if (vm.attemptToSendMedia === 1) {
                    toastr.warning('Erro ao enviar a mensagem');
                }

                if (vm.attemptToSendMedia < 1) {
                    $timeout(function() {
                        vm.attemptToSendMedia = vm.attemptToSendMedia + 1;

                        if ($window.location.href.includes('communication'))
                        sendAudioMessageToWhatsapp(number, media, type, text, stickerApi, message);
                    }, 2000);
                } else {
                    message.loadingDownload = false;
                    message.failedToSend = true;
                    message.loading = false;
                }
            });
        }

        function getLastMessageId() {
            return vm.openedMessages.length > 1
                ? (vm.openedMessages[vm.openedMessages.length - 2].id
                    ? vm.openedMessages[vm.openedMessages.length - 2].id
                        : vm.openedMessages[vm.openedMessages.length - 2].localMessageId)
                            : null;
        }

        vm.getFailedMessageError = function (message) {
            if (message.type === communicationConstants.TYPES.IMAGE.toLowerCase()) {
                return $translate.instant('communication.errors.imageIsInvalid');
            } else if (message.type === communicationConstants.TYPES.VIDEO.toLowerCase()) {
                return $translate.instant('communication.errors.videoIsInvalid');
            }
        }

        function sendStickerToWhatsapp(number, media, type, text, stickerApi) {
            if (!message) {
                var message = {
                    owner: true,
                    text: text,
                    type: type,
                    data: media,
                    isServer: false,
                    created: new Date().getTime(),
                    api: stickerApi,
                    isSending: true
                };

                vm.openedMessages.push(message);
                vm.text = '';
                vm.updateMessageText()
                scrollToBottom();
            }

            WatiService.sendMessage({ number: number, text: text, media: media, type: type }).then(function () {
                message.loadingDownload = false;
                vm.sendMessageBtnLoading = false;
                message.isSending = false;
                scrollToBottom();
                updateConversationData(number, $localStorage.userId, text);
            }).catch(function (error) {
                toastr.warning('Erro ao enviar a mensagem');
                message.loadingDownload = false;
                message.failedToSend = true;
                message.isSending = false;
            });
        }

        /* Sends files, audio, images and stickers to whatsapp*/
        function sendMediaMessageToWhatsapp(number, media, type, text, stickerApi, message) {
            if(media.fullData && media.fullData.blob) {
                var blob = media.fullData.blob
                delete media.fullData.blob
            }
            var blobSplitted = media.blob.split('/');
            var blobId = blobSplitted[blobSplitted.length - 1];

            if (!message) {
                var message = {
                    owner: true,
                    text: text,
                    type: type,
                    data: media,
                    isServer: false,
                    created: new Date().getTime(),
                    api: stickerApi,
                    localMessageId: blobId,
                    downloadedData: media.blob,
                    loadingDownload: true
                };
                if (type == communicationConstants.TYPES.IMAGE.toLowerCase() ||
                    type == communicationConstants.TYPES.VIDEO.toLowerCase()) {
                    message.data = vm.getUrlImage(message);
                } else if (type == communicationConstants.TYPES.AUDIO.toLowerCase()) {
                    message.data = blob;
                }

                message.sendingProgress = 0;

                vm.openedMessages.push(message);
                clearTextMessage();
                scrollToBottom();

                var lastMessageId = getLastMessageId();

                IndexedDBCommunication.addSendingFiles({
                    message: message,
                    lastMessageId: lastMessageId,
                    conversationId: vm.currentConversation.conversationId,
                    localMessageId: blobId,
                    status: 'SENDING'
                }, function (result) {
                    message.indexedDBId = result;
                    finishFileSending(message, media, blobId);
                });
            } else {
                finishFileSending(message, media, blobId);
            }
        }

        function finishFileSending(message, media, blobId) {
            var base64Chunks = splitBase64FileIntoChunks(media.data, chunkSize);

            var index = 0;
            var messageTime = String(moment().valueOf() / 1000).split('.')[0];
            var conversationId = vm.currentConversation.chatUserId;
            var progressIncrement = Math.floor(100 / base64Chunks.length);

            var chunkData = {
                id: conversationId,
                localMessageId: blobId,
                media: {
                    caption: media.caption,
                    data: base64Chunks[index],
                    mimeType: media.mimeType,
                    isLastChunk: (index + 1 === base64Chunks.length)
                },
                text: media.caption,
                time: messageTime,
                type: message.type
            }

            uploadChunksInOrder(0, message, chunkData, base64Chunks, progressIncrement);
        }

        function uploadChunksInOrder(index, message, chunkData, base64Chunks, progressIncrement) {
            if (index < base64Chunks.length) {
                chunkData.media.isLastChunk = (index + 1 === base64Chunks.length);
                chunkData.media.data = base64Chunks[index];
                var messageFound = _.find(vm.openedMessages, function (messageToFind) {
                    return messageToFind.localMessageId === message.localMessageId;
                });

                WatiService.uploadByChunk(chunkData).then(function (result) {
                    if (index + 1 === base64Chunks.length) {
                        vm.sendMessageBtnLoading = false;
                        if (messageFound) {
                            messageFound.sendingProgress = 100;
                            IndexedDBCommunication.updateSendingFileProgress(message.indexedDBId, 100, function (result) { });
                        }
                        IndexedDBCommunication.updateSendingFileStatus(message.indexedDBId, 'PROCESSING', function (result) { });
                    } else {
                        if (messageFound) {
                            messageFound.sendingProgress = messageFound.sendingProgress + progressIncrement;
                            IndexedDBCommunication.updateSendingFileProgress(message.indexedDBId, messageFound.sendingProgress, function (result) { });
                        }
                    }
                    uploadChunksInOrder(index + 1, message, chunkData, base64Chunks, progressIncrement); // Chame a próxima requisição após o sucesso
                }).catch(function (err) {
                    message.loadingDownload = false;
                    message.failedToSend = true;
                    IndexedDBCommunication.updateSendingFileStatus(message.indexedDBId, 'CANCELED', function (result) { });

                    if (messageFound) {
                        IndexedDBCommunication.updateSendingFileProgress(message.indexedDBId, 0, function (result) { });
                    }
                });
            }
        }

        /* Sends images to Instagram*/
        function sendMediaMessageToFacebook(file) {
            file.type = file.type.toUpperCase() === communicationConstants.TYPES.DOCUMENT ? communicationConstants.TYPES.FILE : file.type;
            var body = {
                recipient: {
                    id: vm.currentConversation.chatUserId
                },
                platform: vm.currentConversation.platform.toUpperCase(),
                type: file.type.toUpperCase(),
                file: {
                    name: file.name,
                    extension: file.mimeType,
                    body: file.base64
                }
            };
            updateConversationData(vm.currentConversation.chatUserId, $localStorage.userId, file.name);
            MessengerService.sendMedia(body).then(function (result) {
                var message = {
                    owner: true,
                    data: file.fullBase64,
                    type: file.type.toLowerCase(),
                    created: timeConverter(new Date().getTime()),
                    isServer: false,
                    socialMediaName: vm.currentConversation.platform
                };
                vm.openedMessages.push(message);
            });
        }

        function array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        };

        /**
         * Opens the modal to recommendation and return Promise to get callback when modal close.
         */
        function openModalToSendFile() {
            return vm.recommendationModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/modal-file/modal-file.html',
                controller: 'ModalFileController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    file: function () {
                        return vm.file;
                    },
                    conversation: function () {
                        return vm.currentConversation
                    }
                },
                keyboard: false
            }).result;
        }

        /**
         * Opens the modal to recommendation and return Promise to get callback when modal close.
         */
        function openModalToSendImages() {
            return vm.recommendationModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/modals/send-images-modal/send-images-modal.html',
                controller: 'SendImagesModalController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    images: function () {
                        return vm.imagesToSend;
                    },
                    conversation: function () {
                        return vm.currentConversation
                    }
                },
                keyboard: false
            }).result;
        }

        /**
         * Extracts information of the base64, as extension (png/jpg/jpeg,pdf), mimeType (image/png or application/pdf).
         * @param {*} base64
         * @returns
         */
        function makeSettingByBase64(base64) {
            var base = base64.split(';')[0];
            var types = communicationConstants.FILE_TYPES;
            return {
                type: types.find(function (type) {
                    return base.split('/')[1] === type.source;
                }).target, mimeType: base.split(':')[1]
            };
        }

        function timeConverter(UNIX_timestamp) {
            var convertedDate = new Date(parseInt(UNIX_timestamp));
            var hour = convertedDate.getHours();
            var min = (convertedDate.getMinutes() < 10 ? '0' : '') + convertedDate.getMinutes();
            var time = hour + ':' + min;
            return time;
        }

        /**
         * Move scroll to bottom in chat.
         */
        function scrollToBottom() {
            $timeout(function () {
                var scroller = document.getElementById("messages-container");
                if (scroller) {
                    scroller.scrollTop = scroller.scrollHeight;
                }
            }, 0, false);
        }

        /**
         * Opens the modal to recommendation.
         */
        vm.openTemplateMessageModal = function () {
            vm.recommendationModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/template-message/template-message.html',
                controller: 'TemplateMessageController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false
            }).result.then(function (template) {
                vm.text = template;
                vm.updateMessageText()
            });
        };

        function checkNumberFormat(number) {
            number = String(number);

            if (number.length == 12) {
                return number.slice(0, 4)  + number.slice(4, number.length);
            }

            return number;
        }

        function getCustomerByWati(conversation) {
            conversation.chatUserId = checkNumberFormat((conversation.chatUserId ?  conversation.chatUserId :  conversation.numberId));

            var customer = {
                fullName: conversation.name ? conversation.name : String(conversation.chatUserId),
                phone: "+" + conversation.chatUserId,
                storeId: storeId,
                createdThrough: conversation.platform
            }
            CustomerService.getOrCreateCustomerByWhatsapp(customer).then(function (result) {
                if (conversation.conversationId === vm.currentConversation.conversationId) {
                    vm.currentCustomer = result.data;
                    loadNotesTags(vm.currentCustomer.id);
                    changeTicketStatusToOpened(conversation, false);

                    if (vm.currentCustomer.userDBId) {
                        loadUserOfferEngagements();
                        loadUserCategoryStatistics();
                    } else {
                        vm.offers = null;
                    }
                }
            }).catch(function (err) {
                console.log(err);
                loadAllFalse();
            });
        }

        vm.openFeedbackPopUp = function () {
            document.getElementById("bottom-sheet").classList.add("active");
            setTimeout(function () {
                document.getElementById("sheet-body").classList.add("active");
            }, 1);
        };

        vm.closeFeedbackPopUp = function () {
            setTimeout(function () {
                document.getElementById("bottom-sheet").classList.remove("active");
            },500);
            document.getElementById("sheet-body").classList.remove("active");
        }

        function getCustomerByFbOrInstagram(data) {
            var customer = {
                fullName: data.name,
                facebookInstagramProfile: data.profilePic,
                storeId: storeId,
                facebookInstagramId: data.chatUserId,
                createdThrough: data.platform
            }
            CustomerService.getOrCreateCustomerByFbInstagram(customer).then(function (result) {
                vm.currentCustomer = result.data;
                changeTicketStatusToOpened(data, false)
                loadNotesTags(vm.currentCustomer.id);

                if (vm.currentCustomer.userDBId) {
                    loadUserOfferEngagements();
                    loadUserCategoryStatistics();
                } else {
                    vm.offers = null;
                }
                scrollToBottom();
            }).catch(function (err) {
                console.log(err);
                loadAllFalse();
            });
        }

        function loadAllFalse() {
            vm.loadingConversations = false;
            vm.loadingMessages = false;
            vm.showSkeleton = false;
            vm.loadingCustomerInfos = false;
        }

        vm.addressFormat = function () {
            var zipcode = vm.currentCustomer.zipcode;
            var address = vm.currentCustomer.address;
            var state = vm.currentCustomer.state;
            var city = vm.currentCustomer.city;
            var addressNumber = vm.currentCustomer.addressNumber;

            if (!zipcode && !address && !state && !city && !addressNumber) {
                return $translate.instant('communication.no-information');
            }

            return (address ? address + (addressNumber ? ', ' : ' ') : '') + (addressNumber ? addressNumber + (city ? ' - ' : ' ') : '') + (city ? city + (state ? ', ' : ' ') : "") + (state ? state + (zipcode ? ', ' : ' ') : '') + (zipcode ? zipcode : '');
        }

        vm.toEditMode = function () {
            if (!vm.isEditMode) {
                vm.isEditMode = true;
                vm.customerEdit = angular.copy(vm.currentCustomer)
                dateTimePicker();
            }
        }

        function dateTimePicker() {
            var format = 'L';

            vm.dateOfBirthOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                showClear: true,
                maxDate: moment()
            };

            angular.element('#dateOfBirth').datetimepicker(vm.dateOfBirthOptions).on('dp.change', function (ev) {
                if (ev.date) {
                    $scope.$apply(function () {
                        vm.customerEdit.birthdate = ev.date.format(format);
                    });
                } else {
                    $scope.$apply(function () {
                        vm.customerEdit.birthdate = undefined;
                    });
                }
            });

            vm.customerEdit.birthdate = vm.customerEdit.birthdate ? moment.utc(vm.customerEdit.birthdate).format(format) : null;
        }

        vm.cancelEditMode = function () {
            document.getElementById('infos').style.display = 'none';
            vm.customerEdit = {};
            vm.isEditMode = false;
            setTimeout(function () {
                document.getElementById('infos').style.display = 'block';
            }, 250)
        }

        vm.saveEditCustomer = function () {
            var format = 'L';
            var customerToSave = angular.copy(vm.customerEdit);
            customerToSave.birthdate = vm.customerEdit.birthdate ? moment.utc(vm.customerEdit.birthdate, format).toDate() : undefined;
            CustomerService.update(customerToSave).then(function (result) {
                toastr.success($translate.instant('customer.updated'))
                vm.currentCustomer = result.data
                vm.cancelEditMode();
            }, function (error) {
                if (error.data.headers["x-adminapp-params"]) {
                    toastr.error($translate.instant(error.data.headers["x-adminapp-params"].concat(".").concat(error.data.headers["x-adminapp-error"])));
                } else {
                    toastr.error($translate.instant("global.messages.somethingWrong"));
                }
            });
        }

        function changeTicketStatusToOpened(conversation, isNewMessage) {
            if (conversation.status === communicationConstants.CHAT_STATUS.NEW || isNewMessage) {
                conversation.status = communicationConstants.CHAT_STATUS.OPENED;
                ChatTicketService.updateChatTicketStatus(conversation.chatTicketId, communicationConstants.CHAT_STATUS.OPENED).then(function (result) {
                    vm.currentConversation.status = result.status;
                    vm.currentConversation.unreadMessages = result.unreadMessages;
                });
            }
        }

        vm.openDropdownStatus = function (index) {
            $('.dropdown').removeClass('open');
            $('#dropdownMenuButtonOpt-' + index).dropdown('toggle');
        }

        vm.changeTicketStatus = function (conversation, newStatus, event) {
            if (event) {
                event.stopPropagation();
            }
            if (newStatus === 'NEW') {
                conversation.unreadMessages = -1;
            }

            ChatTicketService.updateChatTicketStatus(conversation.chatTicketId, newStatus).then(function (result) {
                var findedIndex = vm.conversationsList.findIndex(function (item) {
                    return item.conversationId === conversation.conversationId && item.name === conversation.name;
                });

                vm.conversationsList[findedIndex].status = result.status;
                if (vm.currentConversation.conversationId === conversation.conversationId) {
                    vm.currentConversation.status = result.status;
                }
            });
        }

        vm.changeIsFavorite = function (conversation) {
            ChatTicketService.changeTicketFavorite(conversation.chatTicketId).then(function (result) {
                conversation.isFavorite = result.isFavorite;
            });
        }

        function findConversationByConversationId() {
            if ($localStorage.redirectNotificationData
                && $localStorage.redirectNotificationData.conversationId) {
                var targetId = $localStorage.redirectNotificationData.conversationId;
                var chatPlatform = $localStorage.redirectNotificationData.chatPlatform;
                var conversation = {
                    conversationId: targetId,
                    platform: chatPlatform
                };
                showSelectedConversation(conversation);
            } else {
                $localStorage.redirectNotificationData = null;
            }
        }

        function receiveMessage(conversation) {
            var chatId = conversation.chatUserId;
            var socialMediaId = conversation.conversationId;
            var field = 'chatUserId';
            if (conversation.platform == communicationConstants.SOCIAL_MEDIAS.WATI) {
                var customer = {
                    fullName: conversation.name ? conversation.name : String(conversation.chatUserId),
                    phone: "+" + conversation.chatUserId,
                    storeId: storeId,
                    createdThrough: conversation.platform,
                    socialMediaId: socialMediaId
                }

                CustomerService.getOrCreateCustomerByWhatsapp(customer).then(function (result) {
                    ChatTicketService.getAnExistChatTicket(chatId, conversation.platform, socialMediaId,
                        conversation.lastMessage, moment().valueOf(),
                        conversation.messageType, result.data.id, conversation.attendantId)
                        .then(function (ticket) {
                            var index = vm.conversationsList.findIndex(function (item) {
                                return String(item[field]) == String(conversation[field]);
                            });

                            if (vm.currentConversation[field] == vm.conversationsList[index][field]) {
                                changeTicketStatusToOpened(conversation, true);
                                vm.conversationsList[index].status = communicationConstants.CHAT_STATUS.OPENED;
                            }
                            vm.conversationsList[index].attendantId = ticket.attendantId;
                        });
                })
            } else {
                var customer = {
                    fullName: conversation.name,
                    facebookInstagramProfile: conversation.profile_pic,
                    storeId: storeId,
                    facebookInstagramId: conversation.chatUserId,
                    createdThrough: conversation.platform
                }
                CustomerService.getOrCreateCustomerByFbInstagram(customer).then(function (result) {
                    ChatTicketService.getAnExistChatTicket(chatId, conversation.platform, socialMediaId, conversation.lastMessage || "",
                      moment().valueOf(), conversation.messageType, result.data.id, conversation.attendantId).then(function (ticket) {
                        var index = vm.conversationsList.findIndex(function (item) {
                            return String(item[field]) == String(conversation[field]);
                        });

                        if (vm.currentConversation[field] == vm.conversationsList[index][field]) {
                            changeTicketStatusToOpened(conversation, true);
                            vm.conversationsList[index].status = communicationConstants.CHAT_STATUS.OPENED;
                        }
                        vm.conversationsList[index].attendantId = ticket.attendantId;
                    });
                })

            }
        }

        function updateConversationData(chatId, attendantId, lastMessage) {
            if (vm.currentConversation.attendantId === attendantId) { return; }
            vm.currentConversation.lastMessage = lastMessage;
            vm.currentConversation.lastMessageDate = moment().valueOf();
            vm.currentConversation.messageType = 'text'
            vm.currentConversation.attendantId = attendantId
            var index = vm.conversationsList.findIndex(function (value) {
                return value.conversationId == vm.currentConversation.conversationId;
            });
            vm.conversationsList[index].attendantId = attendantId;
            getCurrentAttendant();
            vm.conversations.set(vm.currentConversation.conversationId, vm.currentConversation);
        }

        function getAllAttendantsPromise() {
            var pageable = {
                size: 100,
                page: 0
            };
            var params = {
                login: "",
                firstName: "",
                lastName: "",
                email: "",
                all: true
            }
            return UserService.listUsersByStoreId(pageable, storeId, params);
        }

        function getAllUsersAttendants() {
            getAllAttendantsPromise().then(function (result) {
                vm.usersAttendants = result.data;
            });
        }

        getAllUsersAttendants();

        function getCurrentAttendant() {
            vm.usersAttendants.forEach(function (item) {
                if (item.id == vm.currentConversation.attendantId) {
                    vm.currentAttendant = item;
                    return;
                }
            });
        }

        vm.getAttendant = function (attendantId) {
            var attendant = vm.usersAttendants.find(function (userAttendant) {
                return userAttendant.id === attendantId;
            })
            return attendant;
        }

        vm.getAssignedAttendantTooltip = function (attendantId) {
            var attendant = vm.getAttendant(attendantId);
            var attendantName = (attendant.firstName ? attendant.firstName : '') + ' ' +
                (attendant.lastName ? attendant.lastName : '');
            return $translate.instant('communication.attendant-attribution', {attendant: attendantName});
        }

        vm.assignConversation = function (attendant) {
            if (attendant.id !== vm.currentConversation.attendantId) {
                ChatTicketService.assignTicketToAttendant(vm.currentConversation.chatTicketId, attendant.id).then(function (result) {
                    var index = vm.conversationsList.findIndex(function (value) {
                        return value.conversationId == vm.currentConversation.conversationId;
                    });
                    vm.conversationsList[index].attendantId = attendant.id;
                    vm.currentConversation.attendantId = attendant.id
                    getCurrentAttendant();
                    if (attendant.id === $localStorage.userId) {
                        toastr.info($translate.instant('communication.info.assigned-to-you'));
                    } else {
                        toastr.info($translate.instant('communication.info.assigned-to',
                            { attendant: attendant.firstName.concat(' ').concat(attendant.lastName) }));
                    }
                });
            }
        }

        vm.openSocialNetworksConnect = function () {
            vm.socialNetworksModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/social-networks-connect/social-networks-connect.html',
                controller: 'SocialNetworksConnectModalController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    token: function () {
                        return vm.wsToken;
                    }
                },
            }).result.then(function (response) {
                if (response == 'facebook') {
                    vm.facebookAuth();
                } else if (response.source == 'whatsapp') {
                    if (response) {
                        vm.watiSetting = response.data.watiAccountData;
                        vm.isWatiConnected = true;
                        vm.loadingConversations = true;
                        $localStorage.chatFilters.filtered.pageNumber = 0;
                        $localStorage.chatFilters.selectedFilters.pageNumber = 0;
                        vm.conversationsList = [];
                        vm.conversations = new Map();
                        searchByFilter($localStorage.chatFilters.filtered);
                        getInfoAndSubscribeWhatsapp();
                    }
                }
                verifyAllChatsConnected();
            });
        };

        vm.openWatiSettingsModal = function () {
            vm.watiSettingsModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/social-networks-connect/wati-settings-modal/wati-settings-modal.html',
                controller: 'WatiSettingsModalController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    token: function () {
                        return vm.wsToken;
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    vm.watiSetting = response.data.watiAccountData;
                    vm.isWatiConnected = true;
                    vm.loadingConversations = true;
                    $localStorage.chatFilters.filtered.pageNumber = 0;
                    $localStorage.chatFilters.selectedFilters.pageNumber = 0;
                    vm.conversationsList = [];
                    vm.conversations = new Map();
                    searchByFilter($localStorage.chatFilters.filtered);
                    getInfoAndSubscribeWhatsapp();
                }
            });
        };

        vm.facebookAuth = function () {
            FBAuthModalFactory.connect(true).then(function (data) {
                if (data) {
                    vm.loadingConversations = true;
                    $localStorage.chatFilters.filtered.pageNumber = 0;
                    $localStorage.chatFilters.selectedFilters.pageNumber = 0;
                    vm.conversationsList = [];
                    vm.conversations = new Map();
                    var filters = angular.copy($localStorage.chatFilters.filtered);
                    filters.socialMedias = [vm.currentSocialMedia];
                    vm.fbConnected = true;
                    searchByFilter(filters);
                    isFacebookConnected();
                }
            });
        }

        function getInfoAndSubscribeWhatsapp() {
            WatiService.findConfigByCurrentStore(function (result) {
                if (result.data.id) {
                    vm.watiSetting = result.data;
                    vm.isWatiConnected = true;
                    ChatService.subscribeOnWhatsapp(vm.wsToken, subscribeOnWhatsapp);
                } else {
                    vm.isWatiConnected = false;
                }
            }, function (err) {
            });
        }

        vm.checkCEP = function () {
            if (vm.customerEdit.zipcode && vm.customerEdit.zipcode.length === 8) {
                CheckCEPService.getAddress(vm.customerEdit.zipcode).then(function (response) {
                    var result = response.data;
                    if (result) {
                        vm.customerEdit.address = result.name ? result.name + (result.district.name ? ", " + result.district.name : "") : "";
                        vm.customerEdit.state = result.location.uf;
                        vm.customerEdit.city = result.location.name;
                    }
                }).catch(function (err) {
                    console.log(err)
                });
            }
        }

        vm.changePage = function () {
            vm.paginationOptions.page = vm.currentPage - 1;
            if (vm.currentCustomer.userDBId) {
                loadUserOfferEngagements();
            }
        }

        /**
         * Opens the modal to log out of Whatsapp.
         */
        vm.openLogoutModal = function (socialMedia) {

            if (!vm.isWatiConnected && socialMedia == communicationConstants.SOCIAL_MEDIAS.WATI) return;
            if (!vm.fbConnected && socialMedia == communicationConstants.SOCIAL_MEDIAS.FACEBOOK) return;

            vm.recommendationModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/logout-modal/logout.html',
                controller: 'LogoutModalController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                resolve: {
                    watiSettingId: function () {
                        return vm.isWatiConnected ? vm.watiSetting.id : 0;
                    },
                    socialMedia: function () {
                        return socialMedia
                    }
                }
            }).result.then(function (response) {
                if (response.isLogginOut) {
                    $localStorage.chatFilters.filtered.pageNumber = 0;
                    $localStorage.chatFilters.selectedFilters.pageNumber = 0;
                    vm.conversationsList = [];
                    vm.conversations = new Map();
                    if (response.socialMedia === communicationConstants.SOCIAL_MEDIAS.FACEBOOK) {
                        vm.fbConnected = false;
                    } else {
                        vm.isWatiConnected = false;
                    }
                    searchByFilter($localStorage.chatFilters.filtered);
                    verifyAllChatsConnected();
                }
            });
        };

        function getCategoryName(flyer) {
            FlyerCategoryService.getCategoryTitleByKey(flyer.categoryKey, $translate.use()).then(function (response) {
                flyer.category = response.data;
            });
        }

        function loadUserCategoryStatistics() {
            vm.loadingUserCategoryStatistics = true;
            UserEngagementService.getUserCategoryStatistics(vm.currentCustomer.userDBId, 'all').then(function (result) {
                vm.categoryStatistics = result.mostLikedCategories;
                vm.totalBrand = result.total;
                vm.loadingUserCategoryStatistics = false;
            }, function (err) {
                vm.loadingUserCategoryStatistics = false;
            });
        }

        function loadUserOfferEngagements() {
            vm.loadingUserOfferEngagements = true;
            UserEngagementService.getUserOfferEngagements(vm.currentCustomer.userDBId, 'all', vm.paginationOptions).then(function (result) {
                vm.offers = result.content;

                angular.forEach(vm.offers, function (offer) {
                    getCategoryName(offer.flyer);
                });

                vm.totalElements = result.totalElements;
                vm.startElement = (result.number * result.size) + 1;
                vm.ofQuantityElements = (result.number * result.size) + result.numberOfElements;

                vm.loadingUserOfferEngagements = false;
            }, function (err) {
                vm.loadingUserOfferEngagements = false;
            });
        }

        vm.goToElement = function (element) {
            if (element === 'listConversations') {
                vm.currentConversation = {}
            }
            changeMobileFlow(element);
        }

        function changeMobileFlow(source) {
            if (detectar_mobile()) {
                Object.keys(vm.mobileFlow).map(function (item) {
                    vm.mobileFlow[item] = source === item ? true : false;
                });
            }
        }

        function resetChatCommercePromises() {
            conversationsPromises.forEach(function (promise) {
               promise.payload.cursor = null;
               promise.payload.lastId = null;
            });
        }

        vm.findByName = function () {
            var value = vm.searchText.trim();
            if (value.length == 0) {
                vm.conversationsList = [];
                vm.conversations = new Map();
                vm.loadingConversations = true;
                vm.init();
            } else {
                vm.conversationsList = [];
                vm.conversations = new Map();
                vm.loadingConversations = true;
                var filters = angular.copy($localStorage.chatFilters.filtered);
                filters.pageNumber = 0;
                $localStorage.chatFilters.filtered.pageNumber = 0;
                $localStorage.chatFilters.selectedFilters.pageNumber = 0;
                searchByFilter(filters);
            }
        }

        function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
            var startTimeInMs = Date.now();
            (function loopSearch() {
                if (document.getElementById(selector) != null) {
                    callback();
                    return;
                } else {
                    setTimeout(function () {
                        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                            return;
                        loopSearch();
                    }, checkFrequencyInMs);
                }
            })();
        }

        vm.confirmReprocess = function(message) {
            AudioModalFactory.confirmAction().then(function(data) {
                if(data) {
                    sendMediaMessageToWhatsapp(message.number, message.media, message.type, message.text, message.stickerApi);
                } else {
                    var failedMessages = $localStorage.failedMessages ? $localStorage.failedMessages : {};
                    var storeMessages = failedMessages[storeId];
                    if (storeMessages && storeMessages[message.number]) {
                        failedMessages[storeId][message.number] = storeMessages[message.number].filter(function (m) {
                            return m.media != message.media;
                        });
                        $localStorage.failedMessages = failedMessages;
                        vm.failedMessages = failedMessages[storeId][message.number];
                    }
                }
            });
        }

        vm.recordingPressed = false;

        vm.showRecordButton = function () {
            if (vm.recording) {
                return vm.currentConversation.platform === 'WATI' && vm.recordingPressed;
            } else {
                var inputText = document.getElementById('message-input').innerText;
                return vm.currentConversation.platform === 'WATI' && inputText.trim().length == 0;
            }
        }

        function verifyPermission() {
            MediaDeviceService.verifyMicrophonePermission().then(function (result) {
                $localStorage.micPermission = result;
            },
            function (err) {
                $localStorage.micPermission = 'none';
                vm.discardAudio();
            });
        }

        vm.requestAudioRecordPermissionAndRecord = function(pressed) {
            switch ($localStorage.micPermission) {
                case 'granted':
                    vm.recordAudio(pressed);
                    break;
                case 'denied':
                    toastr.warning($translate.instant('communication.media.audio.error.general-error'));
                    vm.discardAudio();
                    break;
                case 'prompt':
                    requestMicrophonePermission();
                    break;
                default:
                    requestMicrophonePermission();
                    break;
            }
        }

        function requestMicrophonePermission() {
            MediaDeviceService.getAudioRecorder().then(function (result) {
                if (result) {
                    $localStorage.micPermission = 'granted';
                    vm.recorder = result;
                    vm.recorder.deactivateMicrophone();
                    vm.discardAudio();
                    toastr.success($translate.instant('communication.media.audio.success.device-permission-granted'));
                } else {
                    vm.discardAudio();
                    toastr.warning($translate.instant('communication.media.audio.error.general-error'));
                }
            },
            function (err) {
                vm.discardAudio();
                verifyPermissionError(err);
                verifyPermission();
            });
        }

        vm.recordAudio = function (pressed) {
            if (!navigator.permissions) {
                vm.recording = true;
                vm.recordingPressed = pressed;
                MediaDeviceService.getAudioRecorder().then(function (result) {
                    $localStorage.micPermission = 'granted';
                    vm.recorder = result;
                    vm.audioUrl = null;
                    vm.discarded = false;
                    vm.recorder.start();
                },
                function (err) {
                    vm.discardAudio();
                    verifyPermissionError(err);
                });
            } else {
                MediaDeviceService.verifyMicrophonePermission().then(function (result) {
                    $localStorage.micPermission = result;

                    if (result !== 'granted') {
                        vm.discardAudio();
                        requestMicrophonePermission();
                        return;
                    }

                    vm.recording = true;
                    vm.recordingPressed = pressed;
                    MediaDeviceService.getAudioRecorder().then(function (result) {
                        $localStorage.micPermission = 'granted';
                        vm.recorder = result;
                        vm.audioUrl = null;
                        vm.discarded = false;
                        vm.recorder.start();
                    },
                    function (err) {
                        $localStorage.micPermission = 'none';
                        vm.discardAudio();
                        verifyPermissionError(err);
                    });
                },
                function (err) {
                    $localStorage.micPermission = 'none';
                    vm.discardAudio();
                });
            }
        }

        function verifyPermissionError(errorName) {
            if (errorName == "NotFoundError" || errorName == "DevicesNotFoundError") {
                toastr.warning($translate.instant('communication.media.audio.error.device-not-found'));
            } else if (errorName == "NotReadableError" || errorName == "TrackStartError") {
                toastr.warning($translate.instant('communication.media.audio.error.device-in-use'));
            } else if (errorName == "OverconstrainedError" || errorName == "ConstraintNotSatisfiedError") {
                toastr.warning($translate.instant('communication.media.audio.error.device-overconstrained'));
            } else if (errorName == "NotAllowedError" || errorName == "PermissionDeniedError") {
                toastr.warning($translate.instant('communication.media.audio.error.device-not-allowed'));
            } else if (errorName == "TypeError" || errorName == "TypeError") {
                toastr.warning($translate.instant('communication.media.audio.error.device-not-allowed'));
            } else {
                toastr.warning($translate.instant('communication.media.audio.error.device-not-allowed'));
            }
        }

        vm.stopRecord = function () {
            vm.recorder.stop().then(function (audio) {
                vm.sendMessageBtnLoading = true;
                vm.audioUrl = audio.audioUrl;
                vm.recorder.deactivateMicrophone();
                vm.sendAudioMessage(audio.audioBlob);
                vm.recording = false;
                vm.recordingPressed = false;
            },
            function (error) {
                vm.discardAudio();
                console.log(error);
            });
        }

        vm.playAudio = function () {
            var audio = new Audio(vm.audioUrl);
            audio.play();
        }

        vm.discardAudio = function () {
            vm.audioUrl = null;
            vm.recording = false;
            vm.recordingPressed = false;
            vm.discarded = true;
            if (vm.recorder) {
                vm.recorder.deactivateMicrophone();
                vm.recorder = undefined;
            }
        }

        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for(i = 0; i < rawLength; i++) {
              array[i] = raw.charCodeAt(i);
            }
            return array;
        }

        vm.sendAudioMessage = function (audioBlob) {
            if (vm.currentConversation.attendantId !== $localStorage.userId) {
                assignChatTicketToMeIfNeeded();
            }

            var reader = new FileReader();
            reader.readAsDataURL(audioBlob);
            reader.onload = function () {
                var setting = makeSettingByBase64(reader.result);
                var base64 = reader.result.split('base64,')[1];
                var media = {
                    data: base64,
                    mimeType: setting.mimeType,
                    caption: '',
                    type: setting.mimeType,
                    blob: dataURItoBlob(reader.result),
                    fullData: {
                        base64: base64,
                        fullBase64: reader.result,
                        type: setting.mimeType,
                        mimeType: setting.mimeType,
                        name: 'new audio',
                        caption: '',
                        blob: dataURItoBlob(reader.result)
                    }
                }

                sendAudioMessageToWhatsapp(vm.currentConversation.chatUserId, media, 'audio', '', null, null);
                vm.discardAudio();
                scrollToBottom();
            };
        }

        vm.openChatInvoiceNew = function () {
            vm.closeFeedbackPopUp()
            vm.chatInvoiceNewModal = $uibModal.open({
                templateUrl: 'app/admin/invoices/chat/chat-invoice-new.html',
                controller: 'ChatInvoiceNewController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    currentCustomer: function () {
                        return vm.currentCustomer;
                    },
                    cartProducts: function () {
                        return [];
                    }
                },
            }).result.then(function (response) {
                $rootScope.$emit('$applyResponseFromCreatedOrder', response);
            });
        }

        $rootScope.$on('$applyResponseFromCreatedOrder', function (event, response) {
            if (response && response.message) {
                if (vm.currentConversation) {
                    vm.openConversation(vm.currentConversation);
                }
                vm.text = response.message;
                vm.updateMessageText()
            }
        });

        vm.openMagicModal = function () {
            vm.magicModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/communication/magic-modal/magic-modal.html',
                controller: 'MagicModalController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                resolve: {
                    currentCustomer: function () {
                        return vm.currentCustomer
                    }
                },
                keyboard: false
            }).result.then(function (response) {
                if (response) {
                    vm.text = communicationConstants.TEMPLATE_MESSAGE_RECOMMENDATION.replace('{url}', response.url);
                    vm.updateMessageText()
                    toastr.success('Mala pronta para envio');
                }
            });
        };

        vm.hasConversations = function () {
            return !vm.loadingConversations
                && !(!vm.fbConnected && (vm.currentSocialMedia=='MESSENGER' || vm.currentSocialMedia=='INSTAGRAM'))
                && !((!vm.isWatiConnected) && vm.currentSocialMedia=='WATI');
        }

        function searchByFilter(filters) {
            filters.searchString = vm.searchText.trim();
            filters.connectedChats = {
                isWATIConnected: vm.isWatiConnected,
                isMetaConnected: vm.fbConnected
            }
            if (filters.socialMedias.length === 0) {
                if (vm.currentSocialMedia !== 'ALL') {
                    filters.socialMedias = [vm.currentSocialMedia];
                } else {
                    filters.socialMedias = [];
                }
            } else {

                if (vm.currentSocialMedia !== 'ALL') {
                    var find = _.find(filters.socialMedias, function (socialMedia) {
                        return socialMedia === vm.currentSocialMedia;
                    });
                    if (!find) {
                        vm.conversationsList = [];
                        vm.conversations = new Map();
                        vm.dontScrolled = false;
                        if (!$localStorage.redirectNotificationData) vm.loadingConversations = false;
                        vm.loadingMoreConversations = false;
                        return;
                    } else {
                        filters.socialMedias = [vm.currentSocialMedia];
                    }
                }
            }
            filters = JSON.stringify(filters);

            resetChatCommercePromises();
            vm.dontScrolled = true;
            ChatCommerceService.getConversationsByFilter(filters).then(function (result) {
                vm.dontScrolled = false;
                $localStorage.chatFilters.filtered.pageNumber = $localStorage.chatFilters.filtered.pageNumber + 1;
                $localStorage.chatFilters.selectedFilters.pageNumber = $localStorage.chatFilters.selectedFilters.pageNumber + 1;
                var newConversations = angular.copy(vm.conversationsList);
                result.data.items.forEach(function (conversation) {
                    newConversations.push(conversation);
                    if (!vm.conversations.has(conversation.conversationId)) {
                        vm.conversations.set(conversation.conversationId, conversation);
                    }

                    if (conversation.platform !== communicationConstants.SOCIAL_MEDIAS.WATI) {
                        vm.messenger[conversation.conversationId] = {messages: []};
                        getProfilePicFromUrl(conversation);
                    }
                });
                vm.conversationsList = _.uniqBy(newConversations, function (conversation) {
                    return conversation.conversationId;
                });

                if (vm.isWatiConnected && vm.stickers && vm.stickers.length === 0) getStickers();
                if (!$localStorage.redirectNotificationData) vm.loadingConversations = false;
                vm.loadingMoreConversations = false;
            }, function (err) {
                vm.dontScrolled = false;
                vm.loadingConversations = false;
                vm.loadingMoreConversations = false;
            });
        }

        function getStickers() {
            WatiService.findStickers({ pageNumber: vm.stickersPageNumber, pageSize: 9 }).then(function (result) {
                vm.stickersApi = result.data.api.replace("Bearer ", "");
                vm.stickers = result.data.items;
                vm.stickers.map(function (sticker) {
                    sticker.api = vm.stickersApi.replace("data_default/stickers/{name}", sticker.content.slice(1))
                })
            });
        }

        vm.openFiltersModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/communication/filters-conversation-modal/filters-conversation-modal.html',
                controller: 'FiltersConversationController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                resolve: {
                    attendants: function () {
                        return vm.usersAttendants
                    },
                    filters: function () {
                        return vm.filters
                    },
                    isWatiConnected: function () {
                        return vm.isWatiConnected
                    },
                    isFacebookConnected: function () {
                        return vm.fbConnected
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    vm.numberFilterSelected = 0;
                    response.filtered.pageNumber = 0;
                    vm.filters = response.selectedFilters;
                    vm.filters.pageNumber = 0;
                    $localStorage.chatFilters = {
                        selectedFilters: vm.filters,
                        filtered: response.filtered
                    };

                    if (response.filtered.order && response.filtered.order.field) {
                        vm.numberFilterSelected++;
                    }

                    if (response.selectedFilters.attendants.length > 0) {
                        vm.numberFilterSelected++;
                    }

                    if (response.filtered.status.length > 0) {
                        vm.numberFilterSelected++;
                    }

                    if (response.filtered.socialMedias.length > 0) {
                        vm.numberFilterSelected++;
                    }

                    if (response.filtered.rangeDate) {
                        vm.numberFilterSelected++;
                    }

                    $localStorage.chatFilters.numberFilterSelected = vm.numberFilterSelected;
                    if (vm.currentSocialMedia !== 'ALL') {
                        vm.changeTab(vm.currentSocialMedia);
                    } else {
                        if (verifySelectedFilters(response.selectedFilters)) {
                            vm.conversationsList = [];
                            vm.conversations = new Map();
                            searchByFilter(response.filtered);
                        } else {
                            vm.conversationsList = [];
                            vm.conversations = new Map();
                            vm.loadingConversations = true;
                            vm.init();
                        }
                    }
                }
            });
        }

        function verifySelectedFilters(selectedFiltes) {
            if (selectedFiltes.attendants.length > 0) return true;

            if (selectedFiltes.socialMedias.length > 0) return true;

            if (selectedFiltes.status.length > 0) return true;

            if (selectedFiltes.rangeDate.startDate && selectedFiltes.rangeDate.endDate) return true;

            if (selectedFiltes.order.id) return true;

            return false;
        }

        vm.getAmountSelectedMessages = function () {
            return $translate.instant(vm.selectedMessages.length === 1 ?
                    'communication.message-selected-singular' : 'communication.message-selected-plural',
                { amount: vm.selectedMessages.length })
        }

        vm.closeMessageSelection = function () {
            vm.selectedMessages = [];
            vm.selectMultipleMessages = false;
        }

        vm.closeMessageToReply = function () {
            vm.replyMessage = false;
            vm.messageToReply = null;
        }

        vm.checkIfMessageSelected = function (message) {
            if (!message || (message && message.type === 'RESERVED')) return;
            return _.find(vm.selectedMessages, function (selectedMessage) {
                return selectedMessage.id === message.id;
            });
        }

        vm.updateMessagesToForward = function (message) {
            if (!vm.selectMultipleMessages) return;

            var isMessageSelected = vm.checkIfMessageSelected(message);

            if (!isMessageSelected && vm.selectedMessages.length >= 30) return;

            if (isMessageSelected) {
                _.remove(vm.selectedMessages, function (selectedMessage) {
                    return selectedMessage.id === message.id;
                });
            } else {
                vm.selectedMessages.push(message);
            }
        }

        vm.applyOption = function (option, message) {
            vm.messageOptions[option].doAction(message);
        }

        vm.openForwardMessagesModal = function () {
            $uibModal.open({
                templateUrl: 'app/admin/communication/select-contacts-modal/select-contacts-modal.html',
                controller: 'SelectContactsModalController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                resolve: {
                    selectedMessages: function () {
                        return vm.selectedMessages;
                    },
                    allAttendants: function () {
                        return vm.usersAttendants;
                    }
                },
                keyboard: false
            }).result.then(function (response) {
                if (response) {
                    if (response.contacts.length === 1) {
                        vm.openChatAfterForwardMessages = true;
                    }
                    toastr.success($translate.instant('communication.forward-messages-modal.request-messages.forward-messages-success'));
                    vm.closeMessageSelection();
                }
            });
        }

        vm.openBroadcastListModal = function () {
            $uibModal.open({
                templateUrl: 'app/admin/communication/broadcast-list-modal/broadcast-list-modal.html',
                controller: 'BroadcastListController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    isWatiConnected: function () {
                        return vm.isWatiConnected
                    },
                    watiSetting: function () {
                        return vm.watiSetting;
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    toastr.success($translate.instant('communication.broadcast-list-modal.request-messages.broadcast-success'));
                }
            });
        }
    }

})();
